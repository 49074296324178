.oa-shopping-cart__footer {
  display: flex;
  text-transform: uppercase;
  border-top: 1px solid $gray-300;
  padding: 20px 15px;
  width: 100%;
  height: 70px;
  position: absolute;
  bottom: 0;
  left: 0;

  &__total-items {
    flex: 1;
  }

  &__title {
    @include oa-font-weight-semibold();
    font-size: 9px;
    letter-spacing: 0.1em;
    color: $gray-500;
  }

  &__value {
    font-family: $font-family-sans-serif;
  }
}
