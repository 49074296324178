.oa-group-creation-container {
  width: 621px;

  .group-builder-container {
    margin-bottom: 20px;

    .group-builder-header {
      height: 45px;
      width: 100%;
      border-width: 1px;
      border-style: solid;
      border-color: $gray-300;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border-bottom: 0;
      display: flex;
      padding: 10px 15px;
      line-height: 15px;
      .group-builder-header-icon {
        padding-top: 5px;
        height: 20px;
        .oa-icon {
          font-size: 20px;
          &.include {
            color: theme-color('success');
          }
          &.exclude {
            color: theme-color('warning');
          }
        }
      }
      .group-builder-header-title {
        width: 500px;
        padding-top: 7px;
        padding-left: 10px;
        font-size: 12px;
        color: $gray-700;
        font-weight: 600;
      }
      .group-builder-header-clear-all {
        padding-top: 7px;
        padding-left: 10px;
        font-size: 12px;
        cursor: pointer;
      }
    }

    .oa-grid.group-builder .oa-grid__table .oa-table--inner {
      border-radius: 0;
      .oa-tr .oa-td {
        &.actions {
          .icon-wrapper .icon-hover {
            &:hover {
              .hover-show {
                color: $primary;
                fill: $primary;
              }
            }
          }
        }
      }
    }
  }

  .oa-grid.group-builder {
    .oa-grid__table {
      .no-data {
        color: $gray-700;
        padding-left: 45px;
        background-color: $white;
      }
      .oa-table--inner .oa-tr .oa-td {
        &.actions {
          .icon-wrapper {
            display: inline-block;
            button {
              padding: 0;
              &:focus {
                outline: none;
              }
            }
            .icon-hover {
              .hover-hidden {
                display: inline-block;
              }

              .hover-show {
                display: none;
              }

              &:hover {
                .hover-hidden {
                  display: none;
                }

                .hover-show {
                  display: inline-block;
                }
              }
            }
          }
          button.ui-button--disabled .oa-icon {
            color: $border-color;
            fill: $border-color;
          }
        }
        &:last-child {
          padding-right: 20px;
        }
      }
    }
    &.drop-down .oa-grid__table .oa-table--inner .oa-tr {
      &--hover {
        background: theme-color('primary');
        color: $white;
        .oa-icon {
          color: $white;
          fill: $white;
        }
        .oa-td {
          &.actions button.ui-button--disabled .oa-icon {
            color: $white;
            fill: $white;
          }
        }
      }
    }
  }

  .search-box-container {
    .search-box {
      display: flex;
      width: 100%;
      border: 1px solid $gray-400;
      border-radius: 3px;
      box-shadow: none;
      background-color: $white;

      &:focus-within {
        border: 1px solid $primary;
      }

      .search-input {
        font-size: 12px;
        padding: 10px 15px;
        width: 100%;
        min-width: 0;
        border-radius: 3px;
        border: none;
        outline: none;

        &:focus {
          outline: none;
        }
      }

      .search-box-clear {
        padding: 5.5px 15px 5.5px 0;

        button {
          color: $gray-400;
          padding: 0;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .search-results-container {
    width: 621px;

    .group-builder-dropdown-container {
      width: 621px;

      .oa-tabs--simple {
        background-color: $white;
        border-radius: 3px;
      }
    }
  }
}

.no-header .oa-thead {
  display: none;
}

[data-table-block="fixed"] {
  height: 100% !important;
}

.sortable-column.active {
  .oa-icon {
    display: none;
  }
}
