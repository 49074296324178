.oa-selection-boxes {
  overflow: hidden;
  display: flex;

  .disabled {
    opacity: $disabled-opacity;
    color: inherit;
  }

  .btn {
    font-family: $font-family-sans-serif;
    font-weight: normal;
  }

  .oa-sb-item-wrp {
    display: flex;

    .oa-sb-item {
      font-size: 12px;
      height: 34px;
      border-top: 1px solid $gray-400;
      border-bottom: 1px solid $gray-400;
      border-left: 1px solid $gray-400;
      border-right: 0;
      border-radius: 0;
      padding: 7px 9px;
      margin: 0 0 10px 0;

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
        border-right: 1px solid $gray-400;
      }

      .oa-checkbox {
        position: relative;
        top: 1px;
        margin-right: 7px;
      }

      .oa-checkbox__checkmark{
        opacity: 1;
      }
    }
  }

  .oa-sb-all {
    font-size: 12px;
    border: 1px solid $gray-400;
    height: 34px;
    padding: 8px 9px;
    margin-bottom: 10px;
    margin-right: 10px;

    .oa-checkbox__checkmark{
      opacity: 1;
    }

    .oa-checkbox {
      margin-right: 9px;
    }

    .oa-icon {
      width: 15px;
      height: 15px;
      margin-right: 9px;
      position: relative;
      top: 1px;
    }
  }

  input {
    margin-right: 6px;
  }
}
