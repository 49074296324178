.oa-shopping-cart {
  border: 1px solid $gray-300;
  width: 250px;
  height: 100%;
  position: relative;
  padding-bottom: 70px;

  &__items {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    align-items: center;
  }
  &__no-items {
    justify-content: center;
    height: 90%;
  }
  &__body {
    height: 100%;
    padding: 10px 15px;
    overflow-y: auto;
  }

  &__empty-state {
    align-self: center;
    font-size: 14px;
    @include oa-font-weight-semibold();
    color: $gray-300;
    justify-content: center;
  }

  .oa-reveal {
    display: block;
  }

  .oa-position {
    word-wrap: break-word;
  }
}
