.oa-range-selector--dropdown--row {
  display: flex;
  min-width: 500px;
  margin-bottom: 10px;

  .oa-range-selector--dropdown--boxes {
    display: flex;
    flex: 1;
    align-items: center;

    > span {
      display: inline-block;
      padding: 0 10px;
      font-size: $font-size-body;
    }

    > div {
      flex: 1;
    }
  }

  .oa-range-selector--dropdown--disabled {
    opacity: .5;
    pointer-events: none;

    > span {
      opacity: .7;
    }
  }
}
