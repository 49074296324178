.oa-tabs--simple {
  font-family: $font-family-sans-serif;
  font-size: 13px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid $gray-300;

  .oa-tab {
    .oa-button {
      border-radius: 0;
      position: relative;
      top: 2px;
      cursor: pointer;
      min-width: 100px;
      justify-content: center;
      padding: 10px 15px;
      outline: none;
      background: transparent;
      outline: none;
      border: none;
    }
  }

  .oa-tab--active {
    .oa-button {
      color: $gray-700;
      @include oa-font-weight-semibold();

      border-bottom: 2px solid theme-color('primary');
    }
  }

  .oa-tab--disabled {
    color: $gray-300;
  }
}
