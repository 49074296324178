.oa-dropdown{
  .oa-dropdown__subtitle {
    &.active {
      background-color: $light;
      color: $black;
    }
    &:hover {
      background-color: $primary;
      color: $white;
    }
    &:hover .dropdown-item {
      color: $white;
    }
    &:hover .dropdown-header {
      color: $white;
    }
  }

  .oa-dropdown__label {
    @include oa-font-weight-semibold();
    font-size: $font-size-body;
    color: $gray-700;
  }
}

.dropdown-header {
  padding: 10px 10px 5px 7px;
  line-height: 7px;
  font-size: $font-size-label;
}

.dropdown-item {
  padding: 10px;
  font-size: $font-size-xs;
  line-height: 10px;

  &.active {
    background-color: $light;
    color: $black;
  }

  &:hover {
    background-color: $primary;
    color: $white;
    cursor: pointer;
  }
}

.dropdown-menu {
  padding: 0;
  display: none;
  position: absolute;
  background-color: $white;
  min-width: 160px;
  margin: 0;
  box-shadow: $gray-600 0 2px 4px;
  max-height: 205px;
  max-width: 400px;
  overflow: auto;
}

.dropdown-toggle {
  height: 35px;
  width: 160px;
  font-size: $font-size-xs;
  margin: 0;
  padding-left: 10px;
  padding-right: 15px;
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  border: 1px solid $gray-500;
  background-color: $white;

  &.disabled {
    &:hover {
      cursor: no-drop;
    }

    %caret-position {
      position: absolute;
      right: 5px;
      top: 10px;
    }

    &::after {
      display: none;
    }

    .oa-icon {
      @extend %caret-position;
      position: absolute; right: 10px; top: 10px;
    }
  }

  &::after {
    position: absolute; right: 15px; top: 15px;
    color: $gray-500;
  }

  &:focus {
    border-color: mix(black, $primary, 10%);
    box-shadow: $dark 0 1px 2px;
  }

  &:hover {
    background-color: $white;
  }
}

.oa-filter-option{
  font-family: $font-family-sans-serif;
  font-weight: normal;
  text-align: left;
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  color: $gray-700;

  &.oa-placeholder {
    color: $gray-500;
    font-style: italic;
  }

  & input {
    width: 100px;
    border: none;
    background-color: transparent;
    color: $gray-700;
    text-align: left;
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &::placeholder {
      font-style: italic;
      color: $gray-500;
    }

    &:focus {
      outline-color: transparent;
      outline-style: none;
      color: $gray-700;
      font-style: normal;
    }
  }
  & input:only-child {
    width: 120px;
  }
}
