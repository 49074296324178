.oa-range-slider {
  height: 60px;
  margin: 5px;
  .circle {
    position: absolute;
    border-radius: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .slider-container {
    display: flex;
    align-items: center;
    .rail {
      position: relative;
      height: 3px;
      margin: 0 5px;
      background: $gray-300;
      width: 100%;
      cursor: pointer;
    }

    .rail-marker {
      width: 6px;
      height: 6px;
      background: $white;
      border: 2px solid $gray-300;
      &.end {
        right: -6px;
      }
    }

    .area {
      position: absolute;
      height: 3px;
    }

    .active {
      background: theme-color("primary");
    }

    .handler {
      width: 9px;
      height: 9px;
      &.active {
        background: $white;
        width: 18px;
        height: 18px;
        border: 3px solid theme-color("primary");
        cursor: pointer;
      }
      .inner {
        width: 6px;
        height: 6px;
        left: 50%;
      }
      .oa-tooltip {
        display: none;
        position: absolute;
        bottom: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .oa-tooltip__arrow::after {
          margin-left: 50%;
          transform: translateX(-50%);
          position: absolute;
        }
      }
      &:hover, &.dragging {
        .oa-tooltip {
          display: block;
        }
      }
    }
    .indicator {
      width: 4px;
      height: 4px;
      border: 1px solid $gray-300;
      background-color: $white;
      top: 23px;
      &.active {
        border: 1px solid theme-color("primary");
        background: theme-color("primary");
      }
      label {
        font-size: 12px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        color: $gray-700;
        top: 8px;
        &:before {
          content: attr(data-prefix);
          position: absolute;
          left: 0;
          transform: translateX(-100%);
        }
        &:after {
          content: attr(data-suffix);
          position: absolute;
          right: 0;
          transform: translateX(100%);
        }
      }
    }

    .input-container {
      margin-left: 20px;
      display: flex;
      .input-separator {
        padding: 7px;
        color: $gray-700;
      }
      .oa-position {
        visibility: hidden;
      }
    }

    .has-error.has-error {
      .input-field {
        border-color: theme-color("danger");
      }
      .oa-position {
        visibility: visible;
        .oa-tooltip__container{
          background-color: theme-color("danger");
          color: white;
        }
        .oa-tooltip__arrow:after {
          border-bottom-color: theme-color("danger");
        }
      }
    }
    .input-field {
      border: 1px solid $gray-300;
      border-radius: 5px;
      display: flex;
      align-items: center;
      height: 33px;
      padding: 0 10px 0 10px;
      max-width: 100px;
      overflow: hidden;
      &:focus-within {
        border-color: $blue;
      }

      input {
        flex: 1;
        border: none;
        padding: 0 3px;
        margin: 0;
        outline-style: none;
        width: 100%;
        font-size: 14px;
        color: $gray-700;
      }
      input[type='number'] {
        -moz-appearance: textfield;
      }
      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      span {
        color: $gray-500;
        user-select: none;
        font-size: 12px;
      }
    }
  }
}
