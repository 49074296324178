// Directly imported from ember-ui-kit

.oa-tbody,
.oa-tbody__content {
  // FIXME creates stacking context // will-change: transform;
}

.oa-table--v2,
.oa-tr--each {
  display: flex;
  flex-direction: column;
}

.oa-thead {
  .oa-resize {
    display: block;
  }

  .oa-resize__container {
    align-items: flex-end;
    flex: 1;
    min-width: 0;
  }

  .oa-thead__content,
  .oa-sortable {
    white-space: nowrap;
  }
}

.oa-thead,
.oa-tfoot {
  min-height: max-content;
}

.oa-thead__content {
  display: flex;
  align-items: flex-end;
}

.oa-thead__content,
.oa-tfoot__content {
  overflow: hidden;
}

occluded-content {
  font-size: 0;
}

.oa-thead .oa-resize__container,
.oa-tbody,
.oa-tfoot {
  display: flex;
}

.oa-td {
  overflow: hidden;
  box-sizing: border-box;
  transition: width 150ms ease-out, padding 150ms ease-out;

  white-space: nowrap;
}

.oa-th {
  box-sizing: border-box;
  transition: width 150ms ease-out, padding 150ms ease-out;
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
}

.oa-th .oa-resizable {
  display: block;
  overflow: hidden;
}

.oa-tbody {
  overflow-y: auto;
  flex: 1;

  vertical-collection {
    display: flex;
    flex-flow: column nowrap;
    position: initial;
  }
}

[data-table-block] {
  overflow: hidden;
}

[data-table-block="fixed"] {
  min-width: max-content;
}

[data-table-block="variable"] {
  min-width: 0;
  flex: 1;
}

.oa-tbody__content {
  overflow-x: scroll;
}

.oa-tbody .infinite-list-content {
  padding: 0;
}

.oa-tr,
.oa-tr > .fm-form {
  display: flex;
  min-height: 1em;
  align-items: center;
  min-width: min-content;
}

.oa-tr--measure {
  display: none;
}

.oa-tr {
  overflow: hidden;
}

