.oa-list-builder {
  &__list {
    &__scrollable {
      .oa-tbody {
        max-height: 200px;
      }
    }

    .oa-grid {
      &__table {
        .oa-table--inner {
          border: none;

          .oa-th {
            padding: 5px 20px;

            &:before,
            &:after {
              display: none;
            }

            &:last-child {
              display: none;
              border-right: none;
            }
          }

          .oa-td {
            &:last-child {
              border-right: none;
            }
          }
        }

        .oa-thead {
          color: $gray-700;
          border-bottom: none;
        }

        .oa-th {
          text-transform: none;
          border-left: none;
          text-align: left;
        }

        .oa-tr {
          height: 40px;
          border-bottom: none;
          background-color: $gray-100;

          &--hover {
            background-color: $gray-250;
          }
        }

        .oa-td {
          height: 40px;
          line-height: 12px;
          padding: 10px 20px;

          &.editable {
            .oa-grid__inline-edit__trigger {
              border-bottom: 2px dotted theme-color('primary');
            }
          }

          &.locked {
            color: $gray-500;
          }

          &:last-child {
            padding: 12px 10px;
            border-right: none;
            background-color: $white;
          }

          &:not(:last-child) {
            border-top: 1px solid $gray-300;
          }
        }
      }
    }

    &__simple {
      .oa-grid {
        .oa-th {
          display: none;
        }

        .oa-tr {
          &--odd {
            background-color: $white;
          }

          &:last-of-type {
            .oa-td:not(:last-child) {
              border-bottom: 1px solid $gray-300;
            }
          }

          &--hover {
            background-color: $gray-250;
          }
        }

        .oa-td {
          &:first-child {
            border-left: 1px solid $gray-300;
          }

          &:last-child {
            border-left: 1px solid $gray-300;
          }

          &:not(:last-child) {
            border-top: 1px solid $gray-300;
          }
        }
      }
    }
  }

  &__remove {
    cursor: pointer;

    .oa-tooltip__container {
      display: flex;
      align-items: center;
    }

    [data-icon-name='x-circle'] {
      color: $gray-400;
      font-size: 20px;
    }

    [data-icon-name='x-circle-fill'] {
      color: theme-color('danger');
      font-size: 20px;
    }
  }

  &__add {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__item {
      flex: 1;
    }

    &__icon {
      width: 50px;
      padding: 12px 10px;
      cursor: pointer;

      &__disabled {
        cursor: default;
      }

      [data-icon-name='add'] {
        color: theme-color('primary');
        font-size: 20px;
      }

      [data-icon-name='add-fill'] {
        color: theme-color('primary');
        font-size: 20px;
      }
    }
  }

  &__new-item {
    .oa-grid {
      &__table {
        .oa-table--inner {
          border: none;
        }

        .oa-thead {
          border-bottom: none;
        }

        .oa-th {
          display: none;
        }

        .oa-tr {
          background-color: $white;
          border: none;
        }
      }
    }
  }
}
