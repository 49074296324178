@import "./oa-table";

.oa-grid--full-height {
  height: 100%;
  display: flex;
  flex-direction: column;

  .oa-grid__table {
    flex: 2;
    overflow: auto;
  }
}

.oa-grid {
  .no-data {
    border-left: 1px solid $gray-300;
    border-right: 1px solid $gray-300;
    border-bottom: 1px solid $gray-300;
    padding: 10px 15px;
    font-size: 12px;
    color: $gray-700;
    font-family: $font-family-sans-serif;
  }
}

.oa-grid__actions-bar {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.oa-grid__table .oa-table--inner {
  $type: null;
  $background: $white;
  $row-color-stripe: $gray-100;
  $row-color-hover: $gray-250;
  $link-hover-text-color: none;

  background: $background;
  border-radius: 3px;
  padding: 0;
  height: 100%;
  font-size: 12px;
  border: 1px solid $gray-300;
  border-bottom: none;

  .oa-resizable-w {
    display: none !important; // Overriding js introduced inline styles
  }

  .oa-resizable-e {
    cursor: ew-resize;
  }

  .oa-backdrop {
    z-index: $zindex-modal-backdrop;
  }

  .oa-grid__inline-edit__edit {
    z-index: $zindex-modal;
    border: 1px solid $gray-500;
    box-shadow: 0 2px 4px $gray-600;

    &__field {
      background: $white;
      padding: 15px;
    }

    .inline-edit__label {
      display: block;
      margin-bottom: 8px;
      @include oa-font-weight-semibold();
      color: $gray-700;
    }
    .inline-edit__content {
      display: flex;
      input {
        border-radius: 4px;
        border: 1px solid $gray-400;
        padding: 0 5px;
        &:focus {
          border-color: $primary;
          outline: none;
        }
      }

      .oa-grid__inline-edit__button {
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 10px;
      }
    }

  }

  .oa-thead {
    @include oa-font-weight-semibold();
    border-bottom: 1px solid $gray-300;
    color: $gray-500;
    font-size: 12px;
    text-align: left;
  }

  .oa-tfoot {
    @include oa-font-weight-semibold();
    font-size: 12px;
    border: 1px solid $gray-300;
    color: $gray-500;
    .oa-tr {
      background-color: #fff;
      font-weight: bold;
      text-transform: uppercase;
    }
  }


  [data-table-block="fixed"] {
    .oa-th, .oa-td {
      border-right: none;
      padding: 15px 20px;
    }

    .oa-th, .oa-td {

      &:last-child {
        border-right: 1px solid $gray-300;
      }
    }
  }

  .oa-tr {
    color: $gray-700;
    height: 40px;
    font-family: $font-family-sans-serif;
    border-bottom: 1px solid $gray-300;
    position: relative;


    &--even {
      background: $row-color-stripe;
    }

    &--hover {
      background: $row-color-hover;
    }

    &.oa-tr--measure {
      display: none;
    }
  }

  .oa-th {
    line-height: 15px;
    text-transform: uppercase;
    position: relative;
    border-left: 1px dotted $gray-300;
    background-color: $background;

    .oa-resizable {
      padding: 15px 20px;
    }

    &.oa-sortable-placeholder {
      visibility: visible !important; //Override inline js
      border: 1px solid theme-color('primary-border');
      background: theme-color('active');


      &:first-child, &:last-child {
        border: 1px solid theme-color('primary-border');
      }
    }

    &.oa-sortable-helper {
      border: 1px solid $gray-300;

      &:first-child, &:last-child {
        border: 1px solid $gray-300;
      }
      &:before, &:after {
        border: none;
      }
    }

    &:before {
      content: ' ';
      border: 4px solid transparent;
      border-top-color: $gray-300;
      position: absolute;
      top: 0;
      left: -5px;
    }

    &:after {
      content: ' ';
      border: 4px solid transparent;
      border-top-color: $gray-300;
      position: absolute;
      top: 0;
      right: -5px;
    }

    &:only-child {
      &:before, &:after {
        border: none;
      }
    }

    &:first-child {
      border-left: none;
      &:before {
        border: none;
      }
    }

    &:last-child {
      border-right: none;
      &:after {
        border: none;
      }
    }

    &.hidden {
      padding: 0;
    }

    .oa-icon {
      position: absolute;
    }

    &:not(.active) {
      .oa-icon {
        visibility: hidden;
      }
    }
    &.desc {
      .oa-icon {
        transform: rotate(180deg);
      }
    }
  }

  .oa-td {
    padding: 0 20px;

  }

  .dash-underline {
    display: inline-block;
    border-bottom: 2px dotted $link-hover-text-color;
    text-decoration: none;
    cursor: text;
  }
  a, a:visited {
    text-decoration: none;
    color: $link-hover-text-color;
    background: none;
  }

  .fm-field {
    .schedule-content {
      display: inline-flex !important;
      flex: 1;
      min-width: 0;
      text-align: right;
    }
  }

  .in-toggle--switch {
    margin: 0;
  }

  .align-center {
    text-align: center;
  }

  .align-right {
    text-align: right;
  }

}
//Group builder styles
.oa-grid.group-builder {
  .oa-grid__table {
    .oa-table--inner {
      .oa-th {
        height: 45px;
        padding: 15px 0 0 0;
        text-transform: none;
        border-left: none;
        color: $gray-700;
        .oa-icon, .oa-icon {
          position: relative;
          height: 20px;
          width: 20px;
          font-size: 20px;
        }
        &:first-child {
          padding-left: 15px;
        }
        &:last-child {
          padding-right: 15px;
        }
        &:after,&:before {
          border: none;
        }
        &.title {
          padding-left: 3px;
          .oa-icon {
            [data-icon-name='arrow-down'] {
              display: none;
            }
          }
        }
        &.icon.minus-icon {
          color: theme-color('warning');
        }
        &.actions {
          .oa-export {
            display: inline;
            padding-right: 10px;
          }
          .clear-all {
            color: theme-color('primary');
          }
          [data-ember-action] {
            cursor: pointer;
          }
        }
      }
      .oa-tr {
        &--even {
          background: initial;
        }

        &--hover {
          background: initial;
        }
      }
      .oa-td {
        padding: 0;

        &.title {
          .exclude {
            color: theme-color('danger');
          }
          .invalid {
            color: theme-color('danger');
            vertical-align: baseline;
            .oa-tooltip__container {
              background-color: theme-color('danger');
              color: $white;
            }
            .oa-tooltip [data-my-y='bottom'][data-at-y='top'] .oa-tooltip__arrow::after {
              border-top-color: theme-color('danger');
            }
          }
        }
        &.actions {
          .oa-icon, .oa-icon {
            color: $gray-400;
          }
          [data-ember-action] {
            cursor: pointer;
          }
          .delete-icon-wrapper {
            display: inline-block;
            margin-left: -15px;
            &:hover {
              color: theme-color('primary');
              .oa-icon {
                color: theme-color('primary');
                fill: theme-color('primary');
              }
            }
          }
          .exclude-icon-wrapper {
            display: inline-block;
            &:hover {
              color: theme-color('warning');
              .oa-icon {
                color: theme-color('warning');
                fill: theme-color('warning');
              }
            }
          }
          .include-icon-wrapper {
            display: inline-block;
            &:hover {
              color: theme-color('success');
              .oa-icon {
                color: theme-color('success');
                fill: theme-color('success');
              }
            }
          }
        }
        &:first-child {
          padding-left: 15px;
        }
        &:last-child {
          padding-right: 8px;
        }
      }
    }
    .oa-icon, .oa-icon {
      height: 20px;
      width: 20px;
      font-size: 20px;
    }
    .no-data {
      color: $gray-400;
      padding: 13px 15px;
      border-radius: 0 0 3px 3px;
    }
  }
}
.oa-grid.no-header {
  .oa-grid__table {
    .oa-table--inner {
      .oa-thead {
        display: none;
      }
    }
  }
}
.group-builder-dropdown-container {
  position: absolute ;
  z-index: $zindex-modal;
  max-height: 200px;
  width: 434px;
  &::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    position: absolute;
    border-bottom: 5px solid $gray-400;
    top: 0;
    left: 50%;
  }

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    position: absolute;
    border-bottom: 5px solid $white;
    top: 1px;
    left: 50%;
  }
  .loading-results, .no-results {
    margin-top: 5px;
    color: $gray-400;
    height: 40px;
    border-radius: 4px;
    background-color: $white;
    border: 1px solid $gray-300;
    padding: 11px 15px;
  }
  .no-results {
    color: inherit;
  }
  .group-builder-tabs-container {
    margin-top: 5px;
    border-top: $border-width solid $border-color;
    border-left: $border-width solid $border-color;
    border-right: $border-width solid $border-color;
    border-top-left-radius: $btn-border-radius;
    border-top-right-radius: $btn-border-radius;
    .oa-tab {
      font-size: $font-size-helper;
      .oa-button {
        color: $gray-700;
        text-transform: uppercase;
        justify-content: inherit;
        min-width: inherit;
        padding: 0 10px;
        margin: 10px 0;
        border-right: $border-width dotted $border-color;
      }
      &.oa-tab--active {
        .oa-button {
          border-bottom: none;
        }
      }
    }
    .oa-tab:first-child {
      .oa-button {
        margin: 10px 0 10px 5px;
      }
    }
    .oa-tab:last-child {
      .oa-button {
        border-right: none;
      }
    }
    &+.group-builder.drop-down {
      padding-top: 0;
      .oa-grid__table {
        .oa-table--inner {
          border-top: none;
        }
      }
    }
  }
}
.oa-grid.group-builder.drop-down {
  padding-top: 5px;
  max-height: 200px;
  border-bottom: 1px solid $gray-300;
  border-radius: 3px;
  .oa-grid__table {
    .oa-table--inner {
      .oa-tr {
        .oa-td {
          &.actions {
            .exclude-icon-wrapper, .add-icon-wrapper {
              display: inline-block;
            }
            .exclude-icon-wrapper {
              margin-left: -15px;
            }
          }
        }
        &--hover {
          background: theme-color('light');
          .oa-td {
            &.actions {
              .add-icon-wrapper {
                &:hover {
                  color: theme-color('success');
                  .oa-icon {
                    color: theme-color('success');
                    fill: theme-color('success');
                  }
                }
                &:hover {
                  .oa-button--disabled {
                    color: $gray-300;
                    .oa-icon {
                      color: $gray-300;
                      fill: $gray-300;
                    }
                  }
                }
              }
              .exclude-icon-wrapper {
                &:hover{
                  color: theme-color('warning');
                  .oa-icon {
                    color: theme-color('warning');
                    fill: theme-color('warning');
                  }
                }
                &:hover {
                  .oa-button--disabled {
                    color: $gray-300;
                    .oa-icon {
                      color: $gray-300;
                      fill: $gray-300;
                    }
                  }
                }
              }
              .delete-icon-wrapper {
                &:hover {
                  color: theme-color('primary');
                  .oa-icon {
                    color: theme-color('primary');
                    fill: theme-color('primary');
                  }
                }
                &:hover {
                  .oa-button--disabled {
                    color: $gray-300;
                    .oa-icon {
                      color: $gray-300;
                      fill: $gray-300;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// tree browser styles
.oa-grid__table .tree-browser .oa-table--inner {
  [data-table-block="fixed"] {

    .oa-tr--each {
      &:first-of-type {
        .oa-td {
          text-overflow: ellipsis;
          &:first-of-type {
            padding-left: 0;
            padding-right: 0;
          }
          .no-child-nodes {
            padding-left: 15px;
          }
        }
      }
    }
    .tree-level {
      display: inline-block;
      .tree-indent {
        background: $gray-200;
        border-right: 1px solid $gray-300;
        position: absolute;
        top: 0;
        left: 0;
        height: 40px;
        &[style="width:0px"] {
          display: none;
        }
      }
      .oa-button {
        margin: 0;
        padding: 0;
        height: 40px;
        width: 40px;
        justify-content: center;
        &:focus {
          outline: none;
        }
        .oa-icon {
          color: $gray-700;
          font-size: 12px;
        }
      }
    }
  }
}
