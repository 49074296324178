$oa-range-selector-padding-default: 10px;
$oa-range-selector-margin-default: 5px;
$oa-range-selector-font-size: 11px;
$oa-range-selector-node-width: 31px;
$oa-range-selector-node-height: 34px;
$oa-range-selector-node-wide-value-padding: 12px;
$oa-range-selector-icon-dimension: 15px;
$oa-range-selector-node-border-radius: $border-radius !default;

.oa-range-selector{
  user-select: none;
  flex-direction: row;
  display: flex;
  .oa-tooltip__container{
    color: $white;
    background-color: $gray-700;
  }

  .oa-tooltip [data-my-y="top"][data-at-y="bottom"] {
    .oa-tooltip__arrow::after{
      border-bottom-color: $gray-700;
    }
  }
  .oa-range-selector__rows-containter{
    display: inline-block;
    :first-child{
      margin-top: 0;
    }
  }
  .range-selector-all-btn {
    &.oa-range-selector-button{
      margin: 0 5px 0 0;
    }
  }
  .oa-range-selector-button {
    display: inline-flex;
    vertical-align: top;
    padding-left: $oa-range-selector-padding-default;
    padding-right: $oa-range-selector-padding-default;
    margin: 0 5px 0 5px;
    height: $oa-range-selector-node-height;
    font-size: $oa-range-selector-font-size;
    & p{
      margin-top: 2px;
    }
    .oa-icon, .oa-icon [data-icon-name] {
      margin-right: $oa-range-selector-margin-default;
      width: $oa-range-selector-icon-dimension;
      height: $oa-range-selector-icon-dimension;
    }
    .oa-icon .oa-icon__position{
      top: 1px;
    }
  }
  .oa-checkbox{
    padding-top: 1px;
    margin-right: $oa-range-selector-margin-default;
  }
}
