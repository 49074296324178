/**
 * Applies common styles to turn the element into a circle
 */
@mixin draw-circle($width) {
  border-radius: 50%;
  height: $width;
  width: $width;
}

$browser-default-font-size: 16;
/**
 * Converts a pixel value to rem.
 * For most cases, it means dividing by 16px, since this is the default browser setting.
 */
@function rem($px) {
  // $px / ($px * 0 + 1) => trick to strip the unit from the px value
  @return ($px / ($px * 0 + 1) / $browser-default-font-size) * 1rem;
}
