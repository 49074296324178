.oa-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: $zindex-modal-backdrop;

  &__window {
    max-width: 70vw;
    min-height: 5 * 46;
    min-width: 350px;
    background: $white;
    border-radius: 4px;
    border: 1px solid $gray-300;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
  }

  &__separator {
    height: 0;
    width: 100%;
    margin: 0;
    border: none;
    border-top: 1px solid $gray-300;
  }

  &__body,
  &__footer {
    padding: 15px 15px;
  }

  &__header,
  &__footer {
    display: flex;
    align-items: center;
  }

  &__header {
    padding: 5px 15px 5px 15px;
    font-size: 13px;
    height: 50px;
    justify-content: space-between;
    color: $gray-400;
    background-color: $gray-700;
    .oa-icon {
      color: $gray-400;
      position: relative;
      top: -4px;
      left: 5px;
    }
    .oa-icon svg {
      height: 22px;
      width: 22px;
    }
  }

  &__body {
    min-height: 0;
    flex: 1;
    padding: 20px;
    color: $gray-700;
    font-size: 14px;
    max-height: calc(100vh - 140px);
    overflow: auto;
  }

  &__footer {
    width: 100%;
    height: 60;
    background-color: $gray-200;
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
  }
}
//extend the component colors with the mixin

@mixin oa-modal($type: '', $header-color: null, $header-background: null, $header-icon-color: null) {
  @if $type == 'warning' {
    @include oa-modal($header-color: $white, $header-background: theme-color("warning"), $header-icon-color: $white )
  }
  @else if $type == 'error' {
    @include oa-modal($header-color: $white, $header-background: theme-color("danger"), $header-icon-color: $white)
  }
  @else {
    .oa-modal {
      &__header {
        @if $header-color {
          color: $header-color;
        }

        @if $header-background {
          background-color: $header-background;
        }

        @if $header-icon-color {
          .oa-icon {
            color: $header-icon-color;
          }
        }
      }
    }
  }
}

//prebake the colors in because encapsulation.
.warning {
  @include oa-modal('warning')
}

.error {
  @include oa-modal('error')
}
