.oa-toggle {
  margin: 0;
  user-select: none;
  cursor: pointer;
  transition: color 0.5s ease;

  &__input {
    display: none;
  }

  &__label {
    padding-left: $oa-toggle-label-padding-left;
    font-family: $font-family-sans-serif;
    font-size: $font-size-body;
    color: $gray-700;
  }

  .oa-icon {
    width: 24px;
    height: 24px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
}
