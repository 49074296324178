.oa-notifications--business-bar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  box-sizing: border-box;
  margin: 5px 0;
  border-radius: 4px;
  padding: 5px 25px;

  background-color: theme-color('primary');

  a {
    @include oa-font-weight-semibold();
    color: inherit;
    text-decoration: none;
  }

  &__message {
    font-size: 14px;
    color: $white;
  }

  &__close {
    font-size: 14px;
    color: $white;
    cursor: pointer;
  }

  &.error {
    background-color: theme-color('danger');
  }
}
