$oa-datatables-header-sorted-bg: $gray-200 !default;
$oa-datatables-header-sorted-active-bg: $gray-300 !default;
$oa-datatables-sorting-icon-size: .8rem !default;

.dataTables_wrapper {
  .table {
    box-sizing: border-box;
    th.sorting_desc,
    th.sorting_asc {
      background-color: $oa-datatables-header-sorted-bg;
      font-weight: bold;
    }

    th.sorting_asc,
    th.sorting_desc,
    th.sorting {
      cursor: pointer;
      outline: none;
      transition: background-color .1s ease-in-out;

      &:active {
        background-color: $oa-datatables-header-sorted-active-bg;
      }
    }

    td.oa-datatables--custom-cell__wrapper {
      padding-top: 0;
      padding-bottom: 0;
      vertical-align: middle;

      .oa-datatables--custom-cell__content {
        display: flex;
      }
    }

    td.oa-datatables--inline-edit-cell {
      text-decoration: $primary underline dotted;
    }

    .oa-sorting-icon {
      float: right;
      margin-top: ($oa-table-header-font-size * $line-height-base - $oa-datatables-sorting-icon-size) / 2;
      height: $oa-datatables-sorting-icon-size;
      width: $oa-datatables-sorting-icon-size;
    }

    padding-bottom: 0;
    background: $white;

    thead th {
      // Cancel Bootstrap's doubling of border width, because of scrolling structure
      border-bottom-width: $table-border-width;
    }

    tbody tr.even {
      background-color: $white;
    }

    tbody tr.odd {
      background-color: $gray-100;
    }
  }

  .oa-row-actions {
    padding: 0;

    .oa-action-btn {
      border-width: 0;
      padding: $table-cell-padding;
      font-weight: bold;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .dataTables_paginate {
    .paginate_button {
      @extend .btn;
      padding: 2px 10px;
      cursor: pointer;
      color: $primary;
      &.current {
        background-color: $primary;
        color: $white;
      }
    }

    .paginate_input {
      color: $oa-table-color;
      font-size: $font-size-xs;
      width: 40px;
    }

    .paginate_page {
      color: $oa-table-color;
      font-size: $font-size-xs;
      margin-left: 10px;
    }

    .paginate_of {
      color: $oa-table-color;
      font-size: $font-size-xs;
      margin-right: 10px;
    }
  }

  &.DTS {
    display: block;
  }

  &.DTS tbody th,
  &.DTS tbody td {
    white-space: nowrap;
  }

  &.DTS div.DTS_Loading {
    text-align: center;
    z-index: 1;
  }

  .dataTables_scrollHead .table {
    margin-bottom: 0;
  }

  &.DTS div.dataTables_scrollBody {
    background: repeating-linear-gradient(30deg, $white, $gray-100, 10px, $white 10px, $white 20px);
  }

  &.DTS div.dataTables_scrollBody table {
    z-index: 2;
  }

  &.DTS div.dataTables_paginate,
  &.DTS div.dataTables_length {
    display: none;
  }
}
