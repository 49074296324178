.oa-tooltip {
  // redlines {
  $arrow-width: 9px;
  $arrow-height: 6px;

  $container-padding-x: 20px;
  $container-padding-y: 10px;
  $container-max-width: 210px;
  $container-max-height: 300px;
  $container-corner: 4px;
  $container-background: $gray-300;

  $content-text-size: 12px;
  $content-text-color: $gray-700;
  // redlines }

  $width: 36px; // assumed one line width
  $height: 36px; // assumed one line height (padding + line

  .oa-position {
    z-index: $zindex-tooltip;
  }

  .oa-reveal__trigger {
    > .oa-button {
      margin: 0;
    }
  }

  [disabled] {
    pointer-events: none;
  }

  &__container {
    padding: $container-padding-y $container-padding-x;
    min-width: $width;
    min-height: $height;
    max-width: $container-max-width;
    max-height: $container-max-height;
    white-space: initial;
    font-size: $content-text-size;
    color: $content-text-color;
    overflow: hidden;
    border-radius: $container-corner;
    max-width: $container-max-width;
    background: $container-background;
    text-align: center;
  }

  &__arrow {
    width: 0;
    height: 0;
    overflow: visible;

    align-self: center; // FIXME

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
    }
  }

  .oa-position {
    display: flex;
  }

  /**
   * Bottom positioned tip
   */
  [data-my-y="top"][data-at-y="bottom"] {
    flex-direction: column-reverse;

    .oa-tooltip__arrow::after {
      margin-top: $arrow-height * -2;
      border-bottom-color: $container-background;

      border-top-width: $arrow-height;
      border-bottom-width: $arrow-height;
      border-left-width: $arrow-width / 2;
      border-right-width: $arrow-width / 2;
    }

    // center
    .oa-tooltip__arrow::after {
      margin-left: $arrow-width / -2;
    }

    &[data-my-x="left"][data-at-x="left"] .oa-tooltip__arrow {
      align-self: flex-start;

      &::after {
        margin-left: $width / 2 - $arrow-width / 2;
      }
    }

    &[data-my-x="right"][data-at-x="right"] .oa-tooltip__arrow {
      align-self: flex-end;

      &::after {
        margin-left: $width / -2;
      }
    }
  }

  /**
   * Top positioned tip
   */
  [data-my-y="bottom"][data-at-y="top"] {
    flex-direction: column;

    .oa-tooltip__arrow::after {
      margin-bottom: $arrow-height * -2;
      border-top-color: $container-background;

      border-top-width: $arrow-height;
      border-bottom-width: $arrow-height;
      border-left-width: $arrow-width / 2;
      border-right-width: $arrow-width / 2;
    }

    // center
    .oa-tooltip__arrow::after {
      margin-left: $arrow-width / -2;
    }

    &[data-my-x="left"][data-at-x="left"] .oa-tooltip__arrow {
      align-self: flex-start;

      &::after {
        margin-left: $width / 2 - $arrow-width / 2;
      }
    }

    &[data-my-x="right"][data-at-x="right"] .oa-tooltip__arrow {
      align-self: flex-end;

      &::after {
        margin-left: $width / -2;
      }
    }
  }

  /**
   * Right positioned tip
   */
  [data-my-x="left"][data-at-x="right"] {
    flex-direction: row-reverse;

    .oa-tooltip__arrow::after {
      margin-left: $arrow-height * -2;
      border-right-color: $container-background;

      border-top-width: $arrow-width / 2;
      border-bottom-width: $arrow-width / 2;
      border-left-width: $arrow-height;
      border-right-width: $arrow-height;
    }

    // center
    .oa-tooltip__arrow::after {
      margin-top: $arrow-width / -2;
    }

    &[data-my-y="top"][data-at-y="top"] .oa-tooltip__arrow {
      align-self: flex-start;

      &::after {
        margin-top: $height / 2 - $arrow-height / 2;
      }
    }

    &[data-my-y="bottom"][data-at-y="bottom"] .oa-tooltip__arrow {
      align-self: flex-end;

      &::after {
        margin-top: $height / -2 + $arrow-height / -2;
      }
    }
  }

  /**
   * Left positioned tip
   */
  [data-my-x="right"][data-at-x="left"] {
    flex-direction: row;

    .oa-tooltip__arrow::after {
      margin-right: $arrow-height * -2;
      border-left-color: $container-background;

      border-top-width: $arrow-width / 2;
      border-bottom-width: $arrow-width / 2;
      border-left-width: $arrow-height;
      border-right-width: $arrow-height;
    }

    // center
    .oa-tooltip__arrow::after {
      margin-top: $arrow-width / -2;
    }

    &[data-my-y="top"][data-at-y="top"] .oa-tooltip__arrow {
      align-self: flex-start;

      &::after {
        margin-top: $height / 2 - $arrow-height / 2;
      }
    }

    &[data-my-y="bottom"][data-at-y="bottom"] .oa-tooltip__arrow {
      align-self: flex-end;

      &::after {
        margin-top: $height / -2 + $arrow-height / -2;
      }
    }
  }
}
