.oa-backdrop {
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  touch-action: none;

  * {
    touch-action: initial;
  }
}
