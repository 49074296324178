// redlines {
$min-tab-width: 150px;
// }

.oa-tabs--horizontal {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid $gray-300;

  .oa-tab {
    .oa-button {
      border-radius: 0;
      position: relative;
      top: 1px;
      cursor: pointer;
      min-width: $min-tab-width;
      @include oa-font-weight-semibold();
      color: $gray-700;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      outline: none;
      background: transparent;
      outline: none;
      min-height: 100%;

      .oa-icon {
        margin-right: 10px;
      }

      .oa-tab--text {
        font-size: 14px;
      }
    }

    &.oa-tab--active {
      .oa-button {
        color: $primary;
      }
    }

    &:after {
      display: block;
      content: '';
      border-bottom: solid 2px $primary;
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }

    &:hover:after {
      transform: scaleX(1);
    }

    &.oa-tab--disabled {
      &:after {
        display: none;
      }
    }

    &.oa-tab--active:after {
      transform: scaleX(1);
    }
  }

  .oa-tab--disabled {
    .oa-button {
      pointer-events: none;
      color: $gray-300;
    }
  }
}
