$min-tab-width: 200px;

.oa-tabs--vertical {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: inline-flex;
  flex-direction: column;
  border: 1px solid $gray-300;
  background: $gray-200;

  .oa-button {
    background: transparent;
    outline: none;
    border: none;
  }

  .oa-tab {
    background: $gray-200;
    border-left: 4px solid transparent;

    > .oa-button {
      border-radius: 0;
      cursor: pointer;
      min-width: $min-tab-width;
      @include oa-font-weight-semibold();
      color: $gray-500;
      display: flex;
      line-height: 24px;
      justify-content: flex-start;
      align-items: center;
      padding: 10px 15px;

      .oa-icon {
        margin-right: 10px;
      }

      .oa-tab--text {
        font-size: 14px;
      }
    }
  }

  .oa-tab:not(.oa-tab--active):not(.oa-tab--disabled):hover > .oa-button {
    color: theme-color('primary');
  }

  .oa-tab--active {
    background: $white;
    border-left: 4px solid theme-color('primary');
  }

  li:not(:last-child):after {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 1px solid transparent;
  }

  li:not(:first-child):not(.oa-tab--active):before {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 1px solid transparent;
  }

  li:not(:last-child).oa-tab--active:after {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 1px solid $gray-300;
  }

  li:not(:first-child).oa-tab--active:before {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-top: 1px solid $gray-300;
  }

  .oa-tab--active {
    .oa-icon {
      color: theme-color('primary');
    }

    .oa-button {
      color: $gray-700;
    }
  }

  .oa-tab--disabled {
    .oa-button {
      pointer-events: none;
      color: $gray-300;
    }
  }

  .oa-tab--dirty {
    svg.oa-tab--dirty-indicator {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-left: -20px;
      position: relative;
      left: 25px;
      z-index: 2;
    }
  }
}

.oa-tabs--vertical--no-highlights {
  .oa-tab--active {
    border-left: 0;
  }

  .oa-tab {
    border-left: 0;
  }
}

.oa-tabs--vertical__group {
  display: flex;
  margin-bottom: 10px;
  margin-bottom: 10px;

  > div > svg {
    width: 25px;
    height: 100%;
    margin-left: 15px;
  }

  > ul {
    padding: 0;
    list-style-type: none;

    .oa-subtab {
      cursor: pointer;
      color: $gray-700;
      line-height: 10px;
      margin-left: -16px;
      border-left: 2px solid transparent;
      font-size: 12px;

      .oa-button {
        padding: 10px 15px;
      }
    }

    .oa-subtab--active {
      border-left: 2px solid theme-color('primary');

      .oa-button {
        @include oa-font-weight-semibold();
      }
    }
  }

  .oa-subtab--dirty svg.oa-subtab--dirty-indicator {
    display: inline-block;
    width: 20px;
    height: 18px;
    line-height: 0;
    margin-left: -22px;
    position: relative;
    left: 22px;
    z-index: 2;
  }


}

.oa-tabs--vertical--collapse {
  .oa-tabs--vertical__group {
    display: none;
  }

  .oa-tab--text {
    display: none;
  }

  .oa-tab {
    border-left: none;

    .oa-button {
      min-width: 0;

      .oa-icon {
        margin: 0;
      }
    }
  }

  .oa-tab--collapse-toggle {
    + li.oa-tab--active:before {
      border-top: 1px solid transparent;
    }
  }
}

.oa-tabs--vertical {
  .oa-tab--collapse-toggle {
    padding: 4px 0;
    border-bottom: 1px solid $gray-300;

    + li.oa-tab--active:before {
      border-top: 1px solid transparent;
    }
  }
}
