.oa-assign-remove {

  $button-height: 24px;
  $button-width: 24px;

  &__container {
    cursor: pointer;
  }

  &__input {
    display: none;
  }

  .btn-on {
    display: block;
    height: $button-height;
    width: $button-width;
    svg {
      margin-top: -4px;
      fill: $success;
      height: $button-height;
      width: $button-width;
    }
  }

  .assign-btn {
    display: block;
    height: $button-height;
    width: $button-width;
    color: $primary;
    svg {
      margin-top: -4px;
      height: $button-height;
      width: $button-width;
    }
  }

  .assign-btn:hover {
    svg {
      fill: $dark;
      color: $dark;
    }
  }

  .unassign-btn {
    display: block;
    height: $button-height;
    width: $button-width;
    svg {
      margin-top: -4px;
      fill: $primary;
      height: $button-height;
      width: $button-width;
    }
  }

  .unassign-btn:hover {
    transform: rotate(90deg);
    transition-property: transform;
    transition-duration: .1s;
    transition-timing-function: linear;
    svg {
      fill: $danger;
    }
  }

  .btn-off {
    display: block;
    height: $button-height;
    width: $button-width;
    svg {
      margin-top: -4px;
      fill: $danger;
      height: $button-height;
      width: $button-width;
    }
  }
}
