.oa-datatables--inline-progress-bar {
  position: absolute;
  z-index: 1;
  width: 0;

  .progress {
    position: inherit;
    background-color: transparent;
    border-radius: 0;
    width: inherit;
    height: inherit;
  }
}
