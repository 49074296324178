@import "../../ember-power-select";

$oa-select-min-height: 33px !default;
$oa-select-base-font-size: $font-size-base !default;
$oa-select-selected-item-font-size: $font-size-xs !default;

.oa-select, .oa-select-multi {
  font-size: $oa-select-base-font-size;
}

.oa-select .ember-power-select-selected-item,
.oa-select-multi .ember-power-select-selected-item {
  font-size: $oa-select-selected-item-font-size;
  min-height: $oa-select-min-height;
  padding: 6px 0 0 2px;
}

.oa-select .ember-power-select-placeholder,
.oa-select-multi .ember-power-select-placeholder {
  font-size: $oa-select-selected-item-font-size;
  font-style: italic;
  padding: 5px 0 0 2px;
  min-height: $oa-select-min-height - 1px;
}

.ember-power-select-trigger {
  min-height: $oa-select-min-height;
  &--active {
    border-color: $primary;
  }
}

li.ember-power-select-option {
  font-size: $font-size-xs;
}

.ember-power-select-status-icon {
  right: 10px;
}
