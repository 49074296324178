$oa-ia-header-background-color: $white !default;
$oa-ia-header-color: $black !default;
$oa-ia-header-padding: 20px !default;
$oa-ia-header-item-padding: 0 10px !default;
$oa-ia-header-item-label-color: $gray-500 !default;
$oa-ia-header-item-separator: 1px dotted $gray-300 !default;

.oa-ia-header {
  display: flex;
  color: $oa-ia-header-color;
  background-color: $oa-ia-header-background-color;
  padding: 19px $oa-ia-header-padding $oa-ia-header-padding;
  font-family: $headings-font-family;

  h2 {
    line-height: 14px;
  }

  &__item {
    padding: $oa-ia-header-item-padding;
    border-left: $oa-ia-header-item-separator;

    &:first-child {
      border-left: none;
    }

    &__label {
      color: $oa-ia-header-item-label-color;
      font-size: 12px;
      padding: 0 0 14px 0;

      &:empty {
        height: 35px;
      }
    }

    &__content {
      font-size: 14px;
      line-height: 13px;
    }
  }
}
