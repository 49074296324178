.oa-grid__filter {
  margin-left: 10px;

  &:first-child {
    margin-left: 0;
  }

  .oa-grid__filter__pill {
    border-color: theme-color('warning');
    color: theme-color('warning');

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;
    background-color: $white;

    &__clear {
      padding-left: 5px;
      font-size: 12px;
    }

    &__icon {
      margin-right: 10px;
    }

    &__filterName {
      cursor: text;
      white-space: nowrap;
      overflow-y: hidden;
      max-width: 280px;
      padding: 1px 5px 0 0;
    }


    .oa-icon {
      color: theme-color('warning');
    }
  }

  .oa-tooltip {
    background-color: $white;
  }

  .oa-position {
    filter: drop-shadow(rgba(0,0,0,0.13) 0 2px 2px);
  }

  .oa-grid__filter__open {
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    position: relative;
    background-color: $white;
    &:hover {
      background-color: $white;
    }

    &__predictives {
      .oa-reveal {
        position: absolute;
        top: 106%;
        left: 0;
        right: 0;
        width: initial;
        max-height: 500px;
        text-align: left;
        border: 1px solid $gray-400;
        border-radius: 0.25rem;
        background-color: $white;
        z-index: 500;

        .oa-reveal__trigger {
          width: 100%;
          padding: 5px;
        }
      }

      &__choice {
        padding: 2px 10px;
        width: 100%;
        cursor: pointer;

        &:hover {
          background-color: $gray-700;
          color: $white;
        }
      }

      &__error {
        width: 100%;
        padding: 2px 10px;
      }
    }

    &__input {
      border: none;
      outline: none;
      padding: 0 5px;
      &:focus {
        outline: none;
      }
    }

    &__clear {
      margin-left: 10px;
      cursor: pointer;

      .oa-icon svg {
        width: 10px;
        height: 10px;
      }
    }

    &__icon {

    }

    .oa-icon {
      color: $gray-600;
    }
  }

  &__closed {
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    position: relative;
    background-color: $white;

    &__icon {
      color: $gray-600;
    }
  }
}
