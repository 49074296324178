.oa-checkbox {
  margin: 0;
  cursor: pointer;

  &__container {
    display: flex;
    align-items: center;
  }

  &__input {
    display: none;
  }

  &__label {
    padding-left: $oa-checkbox-label-padding-left;
    font-family: $font-family-sans-serif;
    font-size: $font-size-body;
    color: $gray-700;

    user-select: none;
  }

  &__checkmark {
    position: relative;
    height: $oa-checkbox-height;
    width: $oa-checkbox-width;

    background-color: $white;
    border: 1px solid $gray-400;
    border-radius: $oa-checkbox-border-radius;

    &::after {
      content: '';
      position: absolute;
      top: $oa-checkbox-checkmark-top;
      left: $oa-checkbox-checkmark-left;

      height: $oa-checkbox-checkmark-height;
      width: $oa-checkbox-checkmark-width;

      border: solid white;
      border-width: $oa-checkbox-checkmark-border-width;

      transform: $oa-checkbox-transform;
    }
  }

  &__input:checked + &__checkmark {
    background-color: theme-color('primary');
    border: 1px solid map-get($oa-btn-border-colors, 'primary');
    border-radius: $oa-checkbox-border-radius;
  }

  &__input:checked + &__checkmark::after {
    display: block;
  }

  &__input:disabled + &__checkmark , &__input:disabled ~ &__label{
    opacity: $disabled-opacity;
    cursor: default;
  }

  &__input:enabled + &__checkmark:hover {
    border: 1px solid map-get($oa-btn-border-colors, 'primary');
    border-radius: $oa-checkbox-border-radius;
  }
}
