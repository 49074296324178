.oa-shopping-cart__item {
  display: block;
  width: 100%;
  border: 1px solid $gray-300;
  padding: 15px 10px;
  margin-bottom: 10px;
  background-color: $gray-100;

  &__container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }

  &__description {
    width: 110px;
    flex: 1;
    margin-right: 10px;
  }

  &__metric {
    width: 60px;
    margin-right: 10px;
  }

  &__remove {
    display: flex;
    align-items: center;
    .oa-button {
      padding: 0;
      vertical-align: middle;
      color: $gray-400;
      background-color: transparent;
    }
  }

  &__title {
    font-size: 9px;
    color: $gray-400;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__value {
    font-size: 11px;
    overflow-wrap: break-word;
    hyphens: none;
    text-overflow: ellipsis;
    overflow: hidden;
  }

}
