.oa-left-nav {
  background: $white;
  display: flex;
}

.oa-left-nav__sidebar {
  border-right: 1px solid $gray-100;
  display: flex;
  flex-direction: column;
  width: 240px;
}

.oa-left-nav__tabs {
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 240px;
  border-right: none;

  &:hover, &.active {
    font-weight: 500;
    svg {
      color: theme-color('primary');
    }
  }

  .oa-left-nav__sidebar__link {
    margin: 0;
    color: $gray-500;
    background-color: $gray-100;
    padding: 13px 15px 13px 20px;

    &:hover, &.active {
      border-left: 3px solid theme-color('primary');
      color: $gray-700;
    }

    &:first-child{
      border-top: 1px solid $gray-200;
    }

    &.active {
      background-color: $white;
      border-right: none;
      border-bottom: 1px solid $gray-200;
      border-top: 1px solid $gray-200;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .oa-icon { display: none; }
  }

  .oa-left-nav__sidebar__hide-all {
    display: none;
  }
}

.oa-left-nav__content .ui-anchor-switch {
  height: 100%;
}

.oa-left-nav__sidebar__link {
  cursor: pointer;
  margin: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0;
  padding: 7px 15px 7px 30px;
  color: $gray-500;
  font-size: 14px;
  line-height: 17px;
  border-left: 2px solid transparent;

  &:hover, &.active {
    line-height: 17px;
    font-weight: 500;
  }

  &.active {
    font-family: 'NHaasGroteskDSPro65', Helvetica, sans-serif;
  }

  svg {
    font-size: 16px;
  }
}

.oa-left-nav__sidebar__hide-all {
  text-align: end;
  padding: 20px 15px 20px 30px;
  font-size: 12px;
  color: $gray-500;
  border-bottom: 1px solid $gray-200;
  font-weight: 500;
  font-family: 'NHaasGroteskDSPro65', Helvetica, sans-serif;

  svg {
    color: theme-color('primary');
  }

  a {
    cursor: pointer;
    color: $gray-500;
    font-weight: 500;
    font-family: 'NHaasGroteskDSPro65', Helvetica, sans-serif;
  }
}


.oa-left-nav__sidebar__group {
  padding: 12px 15px 12px 30px;

  .oa-icon {
    svg {
      width: 25px;
      height: 25px;
    }
  }

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    svg {
      color: $gray-500;
    }


    &:hover, &.active {
      font-weight: 500;
      svg {
        color: theme-color('primary');
      }
    }
  }

  .oa-left-nav__sidebar__link {
    margin: 0 30px 15px -31px;
    padding: 0 0 0 15px;
    color: $gray-500;
    line-height: 17px;

    &:hover, &.active {
      border-left: 2px solid theme-color('primary');
      color: $gray-700;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .oa-icon { display: none; }
  }
}

.oa-left-nav__sidebar__group--active {
  background-color: $gray-100;
  border-bottom: 1px solid $gray-200;
}

.oa-left-nav__sidebar__group__title {
  @include oa-font-weight-semibold();
  font-size: 14px;
  color: $gray-700;
  cursor: pointer;
  line-height: 14px;

  &:hover {
    color: theme-color('primary');
  }

  &__collapse {
    display: none;
  }
}

.oa-left-nav__sidebar__group__title__icon {
  margin-right: 20px;
}

.oa-left-nav__sidebar__group__subgroup {
  display: flex;
  padding: 15px 30px 0 2px;

  svg {
    width: 25px;
    height: 100%;
    margin-right: 15px;
  }
}
