$oa-datatables--inline-edit-padding: 15px !default;

.oa-datatables--inline-edit {
  &__popup {
    position: fixed;
    z-index: $zindex-modal;
    display: flex;

    margin-top: -$oa-datatables--inline-edit-padding;
    margin-left: -$oa-datatables--inline-edit-padding;
    padding: $oa-datatables--inline-edit-padding;

    background-color: $body-bg;
    box-shadow: 0 2px 6px rgba($black, .17);
  }

  &__body {
    min-width: 150px;
  }

  &__form {
    display: flex;

    .form-control {
      flex-grow: 1;
    }

    .btn {
      margin-left: 10px;
    }
  }

  &__field-label {
    display: block;
  }

  .invalid-feedback {
    display: flex;
    align-items: center;

    .oa-icon {
      font-size: 24px;
      color: $danger;
      fill: $danger;
      margin-right: 10px;
    }
    .error-message {
      max-width: 330px;
      line-height: 16px;
    }
  }

  .close-button {
    padding: 0;
    right: -10px;
    position: relative;
    top: -15px;

    .oa-icon {
      font-size: 20px;
      color: $gray-400;
      fill: $gray-400;
    }
  }

  .oa-loader {
    @include oa-loader-size(1px);
    width: 16px;

    &__dot::after {
      background: $white;
    }
  }
}
