.oa-rightrail {
  border: 1px solid $gray-300;
  width: 330px;
  background-color: $white;
  overflow-y: auto;
  height: 100%;
  position: relative;
  &__tabs {
    height: 40px;
    background-color: $gray-100;
    border-bottom: 1px solid $gray-300;
    padding: 8px 15px;
    .oa-icon {
      color: $gray-500;
    }

    &__tab {
      &__icon {
        margin-right: 15px;
        .oa-icon {
          color: $gray-500;
        }
        &:hover {
          cursor: pointer;
          .oa-icon {
            color: $gray-700;
          }
        }
        &--tabbed {
          @extend .oa-rightrail__tabs__tab__icon;
          .oa-icon {
            color: $gray-700;
          }
        }
      }
    }
  }
  &__banner {
    font-size: $font-size-helper;
    text-transform: uppercase;
    padding: 5px 20px;
    background-color: $gray-100;
    color: $gray-500;
  }
  &__block {
    border-bottom: 1px solid $gray-300;
    padding: 25px 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
  &__title {
    border-bottom: 1px solid $gray-300;
    font-size: $font-size-base;
    color: $gray-700;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__icon {
      .oa-icon {
        font-size: 24px;
        color: $gray-500; //base color - only necessary if the icon color is different than parent's color value
      }
      &:hover {
        cursor: pointer;
        .oa-icon {
          color: $primary;
        }
      }
    }
  }
}

.block-container {
  &--hidden {
    display: none;
  }
}

