// Overrides for standard HTML <table>

$oa-table-color: inherit !default;
$oa-table-font-size: .9rem !default;
$oa-table-row-height: 53px !default;
$oa-table-header-font-size: $oa-table-font-size !default;
$oa-table-header-font-weight: 700 !default;
$oa-table-header-padding: $table-cell-padding !default;
$oa-table-header-text-transform: none !default;

.table {
  color: $oa-table-color;
  font-size: $oa-table-font-size;

  thead th {
    font-size: $oa-table-header-font-size;
    font-weight: $oa-table-header-font-weight;
    padding: $oa-table-header-padding;
    text-transform: $oa-table-header-text-transform;
    user-select: none;
  }
}
