.oa-shopping-cart__header {
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 34px;

  &__selected {
    text-transform: uppercase;
    font-size: 10px;
    @include oa-font-weight-semibold();
  }

  &__remove {
    text-transform: capitalize;
    color: theme-color("primary");
    .oa-button--link {
      padding: 0;
      margin: 0;
      vertical-align: top;
    }

    span {
      font-family: $font-family-sans-serif;
      font-size: 11px;
    }
  }
}
