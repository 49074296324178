$oa-btn-border-colors:      () !default;
$oa-btn-text-transform:     none !default;
$oa-btn-horizontal-margin:  10px !default;

button {
  &, &:hover {
    background: transparent;
  }
  border: 0;
}

@mixin make-oa-button($key, $value) {
  @include make-oa-button-color($value);
  @include make-oa-button-border($key, $value);
  @include make-oa-button-active-bg($value);
}

@mixin make-oa-outline-button($key, $value) {
  @include make-oa-button-outline-color($value);
  @include make-oa-button-border($key, $value);
  @include make-oa-button-active-bg($value);
}

@mixin make-oa-button-active-bg($value) {
  &:not(:disabled):active {
    background-color: lighten($value, 2.5%);
  }
}

@mixin make-oa-button-border($key, $value) {
  $border-color: if(map-get($oa-btn-border-colors, $key), map-get($oa-btn-border-colors, $key), $value);

  border-color: $border-color;

  &:active {
    border-color: lighten($border-color, 2.5%);
  }

  &:hover, &:disabled {
    border-color: $border-color;
  }
}

@mixin make-oa-button-color($value) {
  @if (lightness($value) > 80) {
    color: $gray-700;
    &:hover {
      color: $gray-700;
    }
    &:disabled {
      color: $gray-500;
    }
  } @else {
    color: $white;
    &:hover {
      color: $white;
    }
  }
}

@mixin make-oa-button-outline-color($value) {
  color: $gray-700;

  &:disabled {
    color: $gray-500;
  }

  &:hover {
    color: if(lightness($value) > 80, $gray-700, $white);
  }
}

.btn {
  font-size: $oa-btn-font-size;
  text-transform: $oa-btn-text-transform;

  & + .btn:not(.btn-block) {
    margin-left: $oa-btn-horizontal-margin;
  }

  &:disabled {
    cursor: default;
  }

  &:focus {
    // FIXME Chrome only issue - investigate if still happens with newest Bootstrap beta
    box-shadow: none !important;
  }
}

.btn.btn-sm {
  font-size: $oa-btn-font-size-sm;
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include make-oa-button($color, $value);
  }

  .btn-outline-#{$color} {
    @include make-oa-outline-button($color, $value);
  }

  .oa-button.oa-button--#{$color} {
    @extend .btn;
    @extend .btn-#{$color};
    @include make-oa-button($color, $value);
  }
  .oa-button.oa-button-small--#{$color} {
    @extend .btn;
    @extend .btn-#{$color};
    @include make-oa-button($color, $value);
    @extend .btn-sm;
  }
}

.btn-link {
  padding-left: 0;
  padding-right: 0;
  @include oa-font-weight-semibold;

  &:disabled:hover {
    text-decoration: none;
  }
}

.oa-button--link {
  @extend .btn;
  @extend .btn-link;
}

.oa-button {
  &,
  &__prefix,
  &__infix,
  &__postfix {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
  }
}
