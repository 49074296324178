.form-control {
  $border-width-focus: 0.125rem;

  &:focus {
    border-bottom-width: $border-width-focus;
    padding-bottom: $input-padding-y - $border-width-focus / 2;
  }

  &:focus,
  &.is-invalid,
  &.is-invalid:focus {
    border-top-color: $input-border-color;
    border-right-color: $input-border-color;
    border-left-color: $input-border-color;
  }

  &::placeholder {
    font-style: italic;
  }

}

.invalid-feedback {
  font-style: italic;
}

// HACK Firefox has some weird vertical padding for select inputs
select.form-control {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
