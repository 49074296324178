.oa-text {
  height: 35px;
  border: 1px solid $gray-300;
  border-radius: 3px;
  padding: 0 10px;
  margin: 0;
  color: $gray-700;

  background-color: white;
  min-width: 0;
  overflow: hidden;

  input {
    background-color: transparent;

    &::placeholder {
      color: $gray-500;
    }
  }

  &__infix {
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    min-width: 0;
  }

  &:focus {
    border: 1px solid theme-color('primary');
  }

  &--focus {
    border: 1px solid theme-color('primary');
  }

  &--disabled {
    background-color: $gray-100;

    input {
      color: $gray-500;
    }

    [data-icon-name='lock'] {
      fill: $gray-600;
      font-size: 20px;
    }
  }

  &,
  &__prefix,
  &__infix,
  &__postfix {
    display: inline-flex;
    align-items: center;
  }
}
