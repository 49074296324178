.oa-grid__inline-date-edit {
  .daterangepicker {
    // Overriding inline styles introduced by js
    top: 15px !important;
    left: -16px !important;
  }

  .dropdown-menu {
    border: 1px solid $gray-500;
  }

  .oa-date-picker {
    &__input-wrapper {
      width: 230px;
    }

    &-input {
      height: 24px;
    }

    &__calendar-button .oa-button {
      padding: 5px 10px;
    }
  }
}
