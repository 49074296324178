.oa-notifications--container--passive {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: $zindex-popover;
}

.oa-notifications--passive {
  $border-left-width: rem(5px);
  width: $popover-max-width;

  margin-bottom: 15px;
  padding: $popover-body-padding-y $popover-body-padding-x;
  padding-left: $popover-body-padding-x + $border-left-width;

  background: $popover-bg;
  border: $popover-border-width solid $popover-border-color;
  border-radius: $popover-border-radius;
  box-shadow: $popover-box-shadow;
  color: $popover-body-color;
  font-size: $popover-font-size;

  border-left-width: $border-left-width;
  border-left-color: theme-color('primary');

  a {
    @include oa-font-weight-semibold;
    color: $primary;

    &, &:hover {
      text-decoration: underline;
    }
  }

  &__close {
    float: right;
    margin-top: - $popover-body-padding-y + rem(5px);
    margin-right: - $popover-body-padding-x + rem(5px);

    cursor: pointer;
    color: $gray-500;
  }

  &__title {
    font-size: 1.1em;
    @include oa-font-weight-semibold;
    margin-bottom: rem(5px);
  }

  &.success {
    border-left-color: theme-color('success');
  }

  &.info {
    // Default color set earlier
  }

  &.error {
    border-left-color: theme-color('danger');
  }

  &.warning {
    border-left-color: theme-color('warning');
  }
}
