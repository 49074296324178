
.user-menu {
  width: 200px;
  min-height: 260px;
  background: $primary;
  color: $white;
  position: absolute;
  z-index: $zindex-dropdown;
  top: 40px;
  right: -10px;

  a:  {color: $white};
  a:link {
    color: $white;
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
    color: $white;
  }

  .user-menu__icon {
    $logo-height: 30px;
    $logo-width: 30px;
    height: 60px;
    width: 200px;
    .oa-icon {
      .oa-icon__position {
        top: 20px;
        left: 85px;
      }
      width: $logo-width;
      height: $logo-height;
      svg {
        width: $logo-width;
        height: $logo-height;
      }
    }
  }

  .user-menu__name {
    min-height: 14px;
    line-height: 16px;
    width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    word-wrap: break-word;
    text-align: center;
    font-size: 14px;
    font-family: $font-family-sans-serif;
    @include oa-font-weight-semibold;
    margin-bottom: 15px;
    white-space: normal;
  }

  .user-menu__separator {
    width: 170px;
    height: 1px;
    background-color: mix(white, $primary, 20%);;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: -1px;
  }

  .user-menu__list {
    padding-bottom: 15px;
  }

  .user-menu__item {
    height: 40px;
    padding: 10px 20px;
    font-size: 12px;
    text-align: left;
    position: relative;

    &:hover {
      background-color: mix(white, $primary, 20%);;
      color: white;
    }

    .user-menu__item__icon {
      display: inline-block;
      padding-right: 10px;
      .oa-icon {
        width: 18px;
        height: 18px;
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    .user-menu__item__label {
      display: inline-block;
      position: relative;
      font-size: 12px;
      font-family: $font-family-sans-serif;
      @include oa-font-weight-semibold;
    }
  }
}

.user-menu::after {
  content: " ";
  position: absolute;
  height: 7px;
  bottom: 100%;
  right: 15px;
  border-width: 3.5px 7.5px 3.5px 7.5px;
  border-style: solid;
  border-color: transparent transparent $primary transparent;
}
