.global-navigation-bar {
  height: 60px;
  min-width: 700px;
  margin-bottom: 20px;
  background-color: $black;
  color: $white;
  text-align: left;
  white-space: nowrap;
}

.oa-global-nav-burger-menu {
  padding: 12px;
  float: left;
}

.oa-global-nav-left-bar {
  z-index: $zindex-fixed;
  position: relative;
}

.oa-logo-container {
  $logo-height: 24px;
  $logo-width: 74px;
  height: 20px;
  width: 120px;
  float: left;
  margin-top: 16px;
  text-align: left;
  @include oa-font-weight-semibold();
  font-weight: bold;
  font-size: 1.4em;
  .oa-icon {
    .oa-icon__position {
      top: -4px;
    }
    width: $logo-width;
    height: $logo-height;
    svg {
      width: $logo-width;
      height: $logo-height;
    }
  }
}

.gloabal-nav-seat {
  width: 231px;
  height: 60px;
  float: right;
  margin: 0 25px;
  border-left: 1px $gray-600;
}

.oa-global-nav-right-bar {
  width: auto;
  height: 60px;
  float: right;

  .oa-global-nav-icon-bar {
    height: 60px;
    float: right;

    .oa-nav-icon {
      width: 20px;
      height: 20px;
      margin: 20px 15px 0 0;
      float: left;
      cursor: pointer;
      position: relative;
      &:hover {
        color: theme-color('menu-light');
      }
      &.active {
        color: $primary;
      }
    }
  }
}

.oa-global-nav-select {
  color: $black;
  background-color: transparent;
  border: none;
  box-shadow: none;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.navigation-icon {
  width: 20px;
  height: 20px;
  margin: 20px 15px 0 0;
  float: left;
}

.global-nav-icons {
  float: left
};
