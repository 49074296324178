.oa-range-selector__node{
  width: $oa-range-selector-node-width;
  height: $oa-range-selector-node-height;
  padding-top: 8px;
  text-align: center;
  font-size: $oa-range-selector-font-size;
  color: $gray-700;
  border: 1px solid $gray-400;
  margin-bottom: 10px;

  &.is-long-value{
    padding-left: $oa-range-selector-node-wide-value-padding;
    padding-right: $oa-range-selector-node-wide-value-padding;
    width: 100%;
  }

  &.selected-visual{
    background-color: rgba($primary, .35);
    border: 1px solid $blue;
  }

  &:hover {
    background-color: theme-color('primary');
    cursor: pointer;
  }
}