.oa-range-selector__row{
  flex-direction: row;
  display: flex;

  .oa-range-selector__row-nodes-container{
    display: inline-block;
    margin-left: $oa-range-selector-margin-default;
    .oa-range-selector__row-nodes{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .oa-reveal{
      margin-left: -1px;
    }
    .oa-reveal:last-child{
      .oa-range-selector__node{
        border-top-right-radius: $oa-range-selector-node-border-radius;
        border-bottom-right-radius: $oa-range-selector-node-border-radius;
      }
    }
    .oa-reveal:first-child{
      .oa-range-selector__node{
        border-top-left-radius: $oa-range-selector-node-border-radius;
        border-bottom-left-radius: $oa-range-selector-node-border-radius;
      }
    }
  }
}
