.oa-icon {
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle;
  color: currentColor; //base primary icon color
  fill: currentColor; //base secondary icon color
  stroke: none;
  background-image: none; // overrides jQuery ui icon
  pointer-events: none; // events should not be attached to the icon itself, wrap a button

  &, [data-icon-name] {
    width: 1em;
    height: 1em;
  }
  .oa-icon__position {
    position: relative;
    top: -1px;
  }
}

@mixin oa-icon($options: ()) {
  .oa-icon {
    @content;

    @if map-has-key($options, fill) {
      fill: map-get($options, fill);
    }
    @if map-has-key($options, stroke) {
      stroke: map-get($options, stroke);
    }
    @if map-has-key($options, dimension) {
      $dimension: map-get($options, dimension);
      $unit: unit($dimension);

      @if $unit == 'em' {
        width: $dimension;
        height: $dimension;
      }
      @else if $unit == 'px' {
        width: 1em;
        height: 1em;
        font-size: $dimension;
      }
      @else {
        @error "oa-icon#dimension cannot accept #{$unit} unit";
      }
    }
  }
}

#oa-icon-collection {
  display: none;
}
