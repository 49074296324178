.ember-power-select-multiple-remove-btn {
  float: right;
  margin-left: 6px;
}

.ember-power-select-multiple-option {
  margin: 4px 0 3px 3px;
  font-size: $font-size-body;
  color: $dark;
  padding: 2px 5px;
}

.ember-power-select-multiple-trigger {
  padding: 1px 0 0 1px;
}

.ember-power-select-multiple-remove-btn {
  color: $primary;
  fill: $primary;
}

.ember-power-select-multiple-remove-btn:not(:hover) {
  opacity: 0.7;
}
