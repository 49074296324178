.oa-date-picker-container {
  .oa-date-picker__calendar-button {
    position: absolute;
    right: -2px;
    top: 0;
    cursor: pointer;

    .oa-button {
      outline: none;
      padding: 10px 15px;

      &:hover {
        svg {
          color: $gray-800;
        }
      }

      &:active {
        svg {
          color: $gray-600;
        }
      }
    }

    .oa-icon {
      color: $gray-600;
      position: relative;
      top: -2px;
    }

    .oa-icon svg {
      height: 20px;
      width: 20px;
    }
  }

  .oa-date-picker__input-wrapper {
    position: relative;
  }
}

.oa-date-picker.daterangepicker {
  $date-cell-width: 45px;
  $date-cell-height: 28px;
  $range-cell-height: 40px;
  $range-column-width: 125px;
  $inputs-panel-height: 64px;
  $calendar-width: 330px;

  $active-range-color: #DCF3FB;

  max-height: none;
  overflow: visible;
  border-radius: 0;
  background-color: $gray-200;
  width: ($calendar-width * 2 + 2) + ($range-column-width * 2);
  flex-wrap: wrap;

  @mixin today-dot() {
    content: '';
    position: relative;
    display: block;
    border: 2px solid $black;
    @include draw-circle(2px);
    left: 50%;
    transform: translateX(-50%);
  }

  @mixin round-cell {
    border-radius: 50%;
    padding: 0 8px;
  }

  @mixin two-line-inputs() {
    .datepicker-inputs {
      flex-wrap: wrap;

      .control-inputs {
        width: 100%;
        border-top: 1px solid $gray-300;
      }
    }
  }

  @mixin single-column-time-inputs() {
    .time-inputs .calendar-time:first-of-type {
      margin-bottom: 0;
    }
  }

  .time-inputs {
    display: flex;
    flex-wrap: wrap;

    .calendar-time:before {
      float: left;
      padding-right: 5px;
    }

    .start-label, .end-label {
      padding-right: 5px;
      margin-bottom: 0;
    }

    .calendar-time {
      font-size: $font-size-xs;
      font-family: $font-family-sans-serif;
      font-weight: bold;
      display: flex;
      margin: 15px 0;

      select {
        @extend .ember-power-select-trigger;
        @extend .ember-basic-dropdown-content;
        position: relative;
      }
    }

    .combined-calendar-time {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .time-dropdown {
        position: relative;

        .time-input {
          max-width: 90px;
        }

        .options {
          z-index: $zindex-dropdown;
          @extend .ember-power-select-dropdown;

          li {
            @extend .ember-power-select-option;

            &.selected {
              background-color: $light;
            }
          }
        }

        .oa-down-icon {
          width: 12px;
          height: 12px;
          position: absolute;
          right: 8px;
          top: 12px;
        }
      }
    }
  }

  &.single {
    width: $calendar-width + 2;

    @include two-line-inputs();
    @include single-column-time-inputs();
  }

  .calendar {
    border-bottom: 1px solid $gray-300;
    margin: 0;
    width: $calendar-width;
    max-width: none;

    th {
      border: none;
    }

    &.single {
      min-width: 0;
    }

    .daterangepicker_input .input-mini {
      display: none;
    }

    .calendar-table {

      table {
        table-layout: auto;
        border-collapse: collapse;
      }

      border: none;

      .oa-prev-icon, .oa-next-icon {
        height: 12px;
        width: 12px;
        margin-top: 6px;
      }

      thead {
        text-transform: uppercase;
        border-right: 1px solid $gray-300;

        tr:first-of-type {
          border-bottom: 1px solid $gray-300;
          height: 31px;
        }

        .month {
          font-size: $font-size-xs;
          font-family: $font-family-sans-serif;
          @include oa-font-weight-semibold();
          color: $gray-500;
          padding: 6px 0;
        }

        tr:nth-child(2) {
          height: 18px;
          line-height: 18px;
          background-color: $gray-200;

          th {
            font-size: 9px;
            font-family: $font-family-sans-serif;
            @include oa-font-weight-semibold();
            color: $gray-500;
            width: auto;
            border-radius: 0;
          }
        }
      }

      tbody {
        td {
          font-weight: normal;
          width: $date-cell-width;
          height: $date-cell-height;
          padding: 2px 0;
          line-height: $date-cell-height;
          text-align: center;
          font-size: $font-size-helper;
          font-family: $font-family-sans-serif;
          border-right: 1px solid $gray-300;
          background-clip: content-box;
          color: $gray-700;

          &.off {
            color: $gray-500;
          }

          &.in-range, &.active {
            background-color: transparent ;
          }

          &:not(.in-range):not(.start-date):not(.end-date):hover {
            @include round-cell;

            background-color: $active-range-color;
          }

          &.start-date {
            color: $gray-700;
          }

          &.end-date .selection {
            top: 2px;
            left: 4px;
            width: 24px;
            height: 24px;
            position: relative;
            border-radius: 50%;
            border: 1px solid $primary;
            background-color: $white;
            color: $primary;
            line-height: 22px;
          }

          &.start-date.end-date .day {
            @include round-cell;
            position: relative;
            left: 2px;
            width: 28px;
            padding: 0;

            .selection {
              left: 2px;
            }
          }

          &.in-range .day, &.start-date .day, &.end-date .day {
            background-color: $active-range-color;
          }

          &.start-date .day, &:first-of-type.in-range .day {
            border-radius: 40px 0 0 40px;
            margin-left: 7px;
            padding-right: 7px;
          }

          &.end-date .day, &:last-of-type.in-range .day {
            border-radius: 0 40px 40px 0;
            margin-right: 7px;
            padding-left: 7px;
            height: $date-cell-height;
          }
        }

        td.today {
          &:not(.end-date) .day {
            line-height: 20px;

            // before and after are required here to add a dot for "today" and center label without modifying component template
            &:before {
              content: '';
              position: relative;
              display: block;
              height: 4px;
            }
            &:after {
              @include today-dot();
              bottom: 3px;
            }
          }

          &.end-date .day {
            line-height: 23px;

            &:after {
              @include today-dot();

              border-color: $primary;
              bottom: 3px;
            }
          }
        }
      }
    }
  }

  &.ltr .calendar .calendar-table {
    padding: 0;
  }

  .ranges {
    margin: 0;
    line-height: 0;
    height: ($range-cell-height + 2) * 6;

    ul {
      height: auto;
      max-height: ($range-cell-height + 2) * 6;
      display: flex;
      flex-direction: column;
      width: $range-column-width * 2;
      flex-wrap: wrap;

      li {
        font-size: $font-size-body;
        font-family: $font-family-sans-serif;
        padding: 0 0 0 15px;
        line-height: $range-cell-height;
        color: $gray-700;
        border-bottom-color: $gray-300;
        border-right-color: $gray-300;
        border-radius: 0;
        background-color: $white;
        margin-bottom: 0;
        width: $range-column-width;

        &:hover, &.active {
          background-color: $active-range-color;
          border: 1px solid $primary;
        }
      }
    }
  }

  .datepicker-inputs {
    display: flex;
    justify-content: space-between;
    width: 100%;

    button {
      margin: 15px 15px 15px 0;
      float: right;
      @extend .btn;
    }

    .applyBtn {
      @extend .btn-primary;
    }

    .cancelBtn {
      @extend .btn-link;
    }
  }

  &.simple-presets {
    min-width: 0;
    min-height: 0;
    width: auto;

    .calendar {
      display: none;
    }

    .datepicker-inputs {
      display: none;
    }
  }

  &.single-column-ranges {
    width: ($calendar-width * 2 + 2) + $range-column-width;
    .ranges ul {
      width: $range-column-width;
    }
  }

  &.no-ranges {
    width: ($calendar-width * 2 + 2);

    .ranges {
      display: none;
    }

    @include two-line-inputs();
  }

  &.time-picker.no-ranges {
    .oa-date-picker {
      .daterangepicker {
        min-height: ($range-cell-height + 2) * 6 + $inputs-panel-height * 2 + 2;
      }
    }
  }

  @media (max-width: 1023px) {
    &, &.single-column-ranges {
      width: $calendar-width + 2 + $range-column-width;
    }

    .calendar {
      &.left {
        order: 1;
      }
      &.right {
        order: 3;
      }
    }

    .ranges {
      order: 2;

      ul {
        max-height: none;
        width: $range-column-width;
      }
    }

    .datepicker-inputs {
      order: 4;
    }

    &.no-ranges {
      width: $calendar-width + 2;
    }

    @include two-line-inputs();
    @include single-column-time-inputs();
  }
}
