.oa-grid__search {
  margin-left: 10px;

  &:first-child {
    margin-left: 0;
  }

  .oa-tooltip {
    background: $white;
  }

  .oa-position {
    filter: drop-shadow(rgba(0,0,0,0.13) 0 2px 2px);
  }

  .oa-grid__search__open {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;
    background-color: $white;
    &:hover {
      background-color: $white;
    }

    .oa-icon {
      color: $gray-600;
    }

    &__input {
      background: $white;
      border: none;
      outline: none;
      padding: 0 5px;

      &:hover {

      }

      &:focus {
        outline: none;
      }
    }

    &__clear {
      cursor: pointer;
      font-size: 12px;
    }
  }

  &__closed {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin: 0 10px;
    padding: 0 10px;
    background-color: $white;

    .oa-icon {
      color: $gray-600;
    }
  }

  .oa-grid__search__pill {
    border-color: theme-color('menu-primary');
    color: theme-color('menu-primary');
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;
    background-color: $white;
    display: flex;
    justify-content: space-between;
    .oa-icon {
      color: theme-color('menu-primary');
    }

    &__keyword {
      margin-left: 10px;
      cursor: text;
      overflow-y: hidden;
      white-space: nowrap;
      max-width: 280px;
    }

    &__clear {
      margin-left: 10px;
      cursor: pointer;
      font-size: 12px;
    }
  }
}
