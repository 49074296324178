//customizations to override the variables
$blue:    #0F9ACD;
$indigo:  #6278CB;
$purple:  #9B5EE1;
$pink:    #FE7766;
$red:     #DE1010;
$orange:  #FE9B00;
$yellow:  #FFCC00;
$green:   #3DAE9A;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

//gray colors from UX Standards - overrides of bootstrap variables
$white:     white;
$gray-100:  #FBFBFB;
$gray-200:  #F6F6F6;
$gray-250:  #E8E8E8;
$gray-300:  #E2E2E2;
$gray-400:  #B8BEC2;
$gray-500:  #82898C;
$gray-600:  #868e96 !default;
$gray-700:  #495057 !default;
$gray-800:  #353C40;
$gray-900:  #2D2E2F;
$black:     black;

$primary:    #0F9ACE;
$secondary:  $gray-400;
$success:    #66A900;
$info:       $cyan !default;
$warning:    $orange;
$danger:     $red;
$light:      $gray-100 !default;
$dark:       $gray-800 !default;

// FIXME – Latest beta of Bootstrap should not need the next few lines
$theme-colors: ();
$theme-colors: map-merge((
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
), $theme-colors);
$link-color: $primary;
// END-FIXME

// custom variables
$disabled-opacity: .4;

// Typography
$font-family-sans-serif: "Open Sans", sans-serif;
$font-size-xl:      rem(18px);
$font-size-lg:      rem(14px);
$font-size-base:    rem(12px);
$font-size-sm:      rem(11px);
$font-size-xs:      rem(10px);

$font-size-body:    $font-size-base;
$font-size-helper:  $font-size-sm;
$font-size-label:   $font-size-sm;

$font-primary-color: $gray-900;

// General
$border-radius: 3px;

// Input fields
$input-focus-width:         0;
$input-focus-box-shadow:    none;
$input-height:              2rem;
$input-padding-y:           rem(6px);
$input-placeholder-color:   $gray-500;
$input-disabled-bg:         $white;
$oa-input-disabled-color:   $gray-500;
$oa-input-disabled-border-color: $gray-250;

// Forms
$form-feedback-font-size: $font-size-sm;
$oa-label-bottom-margin:  0.25rem;

$btn-padding-y:           rem(7.6px);
$btn-padding-x:           rem(25px);
$btn-padding-y-sm:        rem(1.6px);
$btn-padding-x-sm:        rem(12px);
$btn-font-weight:         700;
$btn-line-height:         1.342;
$input-highlight-color:   #e7f9ff !default;
$input-selection-color:   #d0f1fd !default;
$form-check-input-margin-y: rem(3.2px);

$oa-btn-font-size:        rem(11px);
$oa-btn-font-size-sm:     rem(10px);
$oa-btn-text-transform:   uppercase;
$oa-btn-border-colors: (
  "primary":  $primary,
  "secondary":  $secondary,
);

$table-border-color:      $gray-250;
$table-hover-bg:          #e7f9ff;

$oa-table-color:                  $gray-900;
$oa-table-font-size:              rem(11px);
$oa-table-header-font-size:       rem(10px);
$oa-table-header-font-weight:     normal;
$oa-table-header-text-transform:  uppercase;
$oa-table-header-padding:         14px 15px;

$oa-table-cell-padding-y: 18px;
$oa-table-cell-padding-x: 15px;
$table-cell-padding:      $oa-table-cell-padding-y $oa-table-cell-padding-x;

// Popovers
$popover-border-color:    $gray-300;
$popover-border-radius:   3px;
$popover-body-color:      $gray-700;
$popover-font-size:       $font-size-sm;
$popover-body-padding-x:  rem(10px);
$popover-body-padding-y:  rem(15px);
$popover-box-shadow:      0 1px 4px rgba($black, .08);

// Backgrounds
$ember-power-select-background-color: $white;
$ember-power-select-disabled-background-color: $gray-250;
$ember-power-select-multiple-selection-background-color: $input-selection-color;
$ember-power-select-highlighted-background: $input-highlight-color;
$ember-power-select-selected-background: $gray-200;

// Texts
$ember-power-select-text-color: $font-primary-color;
$ember-power-select-placeholder-color: $secondary;
$ember-power-select-highlighted-color: $font-primary-color;
$ember-power-select-disabled-option-color: #999999 !default;  //TODO
$ember-power-select-multiple-selection-color: #333333 !default; //TODO

// Borders
$ember-power-select-border-color: $gray-300;
$ember-power-select-focus-border-color: $ember-power-select-border-color !default;
$ember-power-select-default-border: 1px solid $ember-power-select-border-color !default;
$ember-power-select-default-focus-border: 1px solid $ember-power-select-focus-border-color !default;

$ember-power-select-dropdown-contiguous-border: none !default;

$ember-power-select-multiple-option-border-color: $gray-400;
$ember-power-select-multiple-option-border: 1px solid $input-selection-color !default;

// Borders radius
$ember-power-select-default-border-radius: $border-radius !default; // General border radius
$ember-power-select-trigger-default-border-radius: $ember-power-select-default-border-radius !default;
$ember-power-select-dropdown-default-border-radius: $ember-power-select-default-border-radius !default;

$ember-power-select-opened-border-radius: 0 !default; // Border radius of the side of the dropdown and the trigger where they touch

$ember-power-select-search-input-border-radius: 0 !default;
$ember-power-select-multiple-option-border-radius: 2px !default;

// Other
$ember-power-select-focus-box-shadow: none !default;
$ember-power-select-dropdown-margin: 0 !default; // Margin between the dropdown and the trigger
$ember-power-select-dropdown-box-shadow: none !default;
$ember-power-select-line-height: 1.9 !default;
$ember-power-select-trigger-icon-color: $font-primary-color !default;
$ember-power-select-clear-btn-margin: 25px !default;
$ember-power-select-option-padding: 8px !default;
$ember-power-select-number-of-visible-options: 7 !default;
$ember-power-select-focus-outline: 0 !default;
$ember-power-select-trigger-ltr-padding: 0 16px 0 0 !default;
$ember-power-select-trigger-rtl-padding: 0 0 0 16px !default;
$ember-power-select-multiple-option-padding: 0 4px !default;
$ember-power-select-multiple-option-line-height: 1.4 !default;

$oa-checkbox-height: 13px;
$oa-checkbox-width: 13px;
$oa-checkbox-border-radius: 0;
$oa-checkbox-label-padding-left: 8px;
$oa-checkbox-transform: rotate(0deg);
$oa-checkbox-checkmark-top: 0;
$oa-checkbox-checkmark-left: 0;
$oa-checkbox-checkmark-height: 11px;
$oa-checkbox-checkmark-width: 11px;
$oa-checkbox-checkmark-border-width: 1px 1px 1px 1px;

$oa-toggle-height: 15px;
$oa-toggle-width: 24px;
$oa-toggle-inner-diameter: 9px;
$oa-toggle-label-padding-left: 8px;
$oa-toggle-toggle-color: $white;

$oa-select-base-font-size: rem(16px);
$oa-select-selected-item-font-size: rem(13px);
