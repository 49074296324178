$oa-datatables-tree-toggle-padding:   rem(13px) !default;
$oa-datatables-tree-toggle-width:     rem(30px) !default;
$oa-datatables-tree-toggle-icon-size: rem(8.65px) !default;

.table {
  .dt-tree-spacer {
    margin-left: $oa-datatables-tree-toggle-width;
    position: relative;

    &::before {
      background-color: $table-border-color;
      content: '';
      height: $oa-table-row-height;
      left: -45px;
      position: absolute;
      top: -18px;
      width: $oa-datatables-tree-toggle-width;
    }
  }

  .dt-tree-toggle {
    // Unfortunatelly, could not calculate this value... I'm copying it here for later computations.
    $icon-width: rem(9px);
    $margin-left: $oa-datatables-tree-toggle-width / 2 - rem(16.5px);
    $margin-right: $oa-datatables-tree-toggle-width - $icon-width - $margin-left - rem(15px);

    margin-top: -$oa-datatables-tree-toggle-padding;
    margin-bottom: -$oa-datatables-tree-toggle-padding;
    margin-right: $margin-right;
    margin-left: $margin-left;
    padding: $oa-datatables-tree-toggle-padding 0;
    text-align: center;
    // width: $oa-datatables-tree-toggle-width;
    transform: rotate(-90deg);
    transition: $transition-base;
    transition-property: transform;

    &:focus {
      outline: none;
    }

    & > svg {
      height: $oa-datatables-tree-toggle-icon-size;
      width: $oa-datatables-tree-toggle-icon-size;
    }

    &.expanded {
      transform: rotate(0deg);
    }
  }

  .dt-tree-childless {
    padding-left: $oa-datatables-tree-toggle-width;
  }
}
