.oa-upload-container {
  border: 2px dashed $gray-300;
  padding: 30px;
  margin-bottom: 20px;
  color: $gray-700;
  display: inline-block;

  span {
    font-family: $font-family-sans-serif;
    font-size: $font-size-body;
    display: inline-block;
    margin: 0 10px 0 0;

    &.oa-dd-text {
      @include oa-font-weight-semibold();
    }
  }

  button span {
    margin: 0;
  }

  .oa-dd-icon {
    position: relative;
    top: -5px;
  }

  .oa-dd-icon svg {
    height: 22px;
    width: 22px;
    fill: $gray-400;
  }
}

.oa-modal__body .oa-upload-container {
  margin-bottom: 0;
  text-align: center;
  width: 100%;

  .oa-dd-icon {
    position: relative;
    top: -5px;
    left: -15px;
    height: 40px;
    margin-bottom: 10px;

    svg {
      height: 40px;
      width: 40px;
    }
  }

  .oa-dd-icon,
  .oa-dd-text {
    display: block;
  }
  button {
    display: block;
    margin: 0 auto;
  }

  span {
    margin: 0;
  }

}
