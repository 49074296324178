$oa-label-bottom-margin:          0.3rem !default;
$oa-input-font-size:              $font-size-base !default;
$oa-input-disabled-color:         inherit !default;
$oa-input-disabled-border-color:  $input-border-color !default;

.form-control {
  font-size: $oa-input-font-size;

  &:disabled,
  &[readonly] {
    border-color: $gray-300;
    color: $gray-700;
    cursor: default;
    background-color: $gray-200;
  }
}

.oa-field-required {
  color: $gray-600;
  cursor: default;
  float: right;
  font-size: $font-size-helper;
  margin-top: .4rem;
}

// FIXME this should leverage Bootstrap labels' classes instead
label {
  margin-bottom: $oa-label-bottom-margin;
  font-size: $font-size-body;
  color: $gray-700;
  @include oa-font-weight-semibold;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  box-shadow: none;
  border-color: $danger;
}
