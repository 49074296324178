.oa-notification-log {
  display: inline-block;
  border: 1px solid $popover-border-color;
  overflow: hidden;
  width: 380px;
  z-index: $zindex-modal;
  background-color: $white;
  position: fixed;

  .oa-tabs--simple {
    border-bottom: 1px solid $gray-300;

    .oa-tab {
      flex-grow: 1;
      border-right: 1px solid $popover-border-color;
      background-color: $gray-200;

      &:last-of-type {
        border-right: none;
      }

      &.oa-tab--active {
        background-color: $gray-100;

        .oa-button {
          color: $gray-700;
          @include oa-font-weight-semibold();
        }
      }

      .oa-button {
        width: 100%;
        min-width: 0;
        border-bottom: none;
        color: $gray-500;
        font-family: $font-family-sans-serif;
      }
    }
  }

  .notifications-container {
    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;

    .oa-notifications--passive {
      margin-bottom: 0;
      width: 100%;
      box-shadow: none;
      position: relative;
      animation: slidein .5s ease;

      .oa-notifications--passive__title {
        display: inline-block;
      }

      .oa-notifications--passive__timestamp {
        font-style: italic;
        margin-bottom: 5px;
        color: $gray-500;
      }

      .oa-notifications--passive__message {
        white-space: normal;
      }

      &.hide {
        animation: slideout .5s ease;
      }
    }
  }

  .notification-log-footer {
    width: 100%;
    border-top: 1px solid $popover-border-color;

    .btn.clear-all {
      padding: 10px;
    }
  }

  .notification-empty {
    padding: 30px;
    text-align: center;
    font-family: $font-family-sans-serif;
    color: $gray-400;
    font-size: $font-size-base;
    font-weight: bold;
  }
}

@keyframes slidein {
  from {
    opacity: 0;
    left: 360px;
  }
  to {
    opacity: 1;
    left: 0;
  }
}

@keyframes slideout {
  from {
    opacity: 1;
    left: 0;
  }
  to {
    opacity: 0;
    left: 360px;
  }
}
