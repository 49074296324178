$oa-radio-checked-border-color: $primary !default;

.form-check {
  // Disable Bootstrap's absolute positioning in favor of some flexing
  line-height: rem(22px);
  padding-left: 0;

  .form-check-input {
    margin-left: 0;
    margin-top: 0;
    margin-right: rem(7px);
  }

  .form-check-input:disabled ~ .form-check-label{
    color: $gray-700;
    opacity: $disabled-opacity;
  }

  .form-check-label {
    display: inline-flex;
    line-height: 1.4;
  }
}

.form-check-header {
  font-size: rem(13px);
  color: $gray-700;
  margin-bottom: rem(14px);
}

.form-check-input[type=radio] {
  $transform: .1s ease-in-out;

  @include draw-circle(rem(14px));
  appearance: none;
  border: 1px solid $gray-400;
  position: relative;
  transition: $transform box-shadow;

  &::after {
    @include draw-circle(rem(8px));
    content: '';
    position: absolute;
    background: $primary;
    top: 2px;
    left: 2px;
    opacity: 0;
    transition: $transform opacity, $transform background-color;
  }

  &.checked, &:checked {
    border-color: $oa-radio-checked-border-color;
    &::after {
      opacity: 1;
    }
  }

  &:disabled {
    opacity: $disabled-opacity;
  }

  &:hover:enabled {
    border: 1px solid $primary;
  }

  &:focus {
    outline: none;
  }

  &:active:not(:disabled) {
    box-shadow: 0 0 2px rgba($gray-400, 10%);
    border-color: darken($oa-radio-checked-border-color, 10%);
    &::after {
      background-color: darken($primary, 10%);
      opacity: 1;
    }
  }
}

.form-check-label {
  // Needed to reset the base label styles, see ./forms.scss
  font-family: $font-family-sans-serif;
  font-weight: normal;
}
