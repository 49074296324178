@keyframes loading {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes orbit {
  0% {
    transform: rotate(225deg);
    opacity: 1;
    animation-timing-function: ease-out;
  }

  7% {
    transform: rotate(345deg);
    animation-timing-function: linear;
  }

  30% {
    transform: rotate(455deg);
    animation-timing-function: ease-in-out;
  }

  39% {
    transform: rotate(690deg);
    animation-timing-function: linear;
  }

  70% {
    transform: rotate(815deg);
    opacity: 1;
    animation-timing-function: ease-out;
  }

  75% {
    transform: rotate(945deg);
    animation-timing-function: ease-out;
  }

  76% {
    transform: rotate(945deg);
    opacity: 0;
  }

  100% {
    transform: rotate(945deg);
    opacity: 0;
  }
}

@mixin oa-loader-size($gutter: 5px) {
  $diameter: $gutter * 10;
  $dimension: $diameter * 1.2;

  width: $dimension;
  height: $dimension;
  padding: $diameter * 0.2;

  &__dot {
    width: $diameter;
    height: $diameter;

    &::after {
      width: $gutter+3;
      height: $gutter+3;
      border-radius: $gutter+3;
    }

    &:nth-child(2) {
      animation-delay: 240ms;
      margin-top: $diameter / -1;
    }

    &:nth-child(3) {
      animation-delay: 480ms;
      margin-top: $diameter / -1;
    }

    &:nth-child(4) {
      animation-delay: 720ms;
      margin-top: $diameter / -1;
    }

    &:nth-child(5) {
      animation-delay: 960ms;
      margin-top: $diameter / -1;
    }
  }
}

.oa-loader {
  @include oa-loader-size();

  &__dot {
    opacity: 0;
    transform: rotate(225deg);
    animation-iteration-count: 1000;
    animation-name: orbit;
    animation-duration: 4.5s;

    &::after {
      content: '';
      display: inline-block;
      background: $primary;
    }

    &:first-child {
      margin-top: 0;
    }
  }
}
