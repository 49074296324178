$oa-burger-menu-bg: #0a87ce;
$oa-burger-menu-bg-hover: #1c9be3;

.oa-burger-menu {
  $hover-transition-time: 0.35s;

  .oa-backdrop {
    position: relative;
  }

  &__trigger {
    padding-top: 14px;
    padding-left: 10px;
    cursor: pointer;
    @include draw-circle(36px);
    color: $white;
    transition: background-color 0.35s ease-in-out;
    .oa-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      .oa-icon__position {
        top: 0;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      background-color: $oa-burger-menu-bg-hover;
    }
  }
  &__menu {
    @include oa-font-weight-semibold();
    color: $white;
    position: fixed;
    top: 0;
    left: -300px;
    width: 300px;
    background: $oa-burger-menu-bg;
    bottom: 0;
    padding: 20px 0 0;
    transition: left 0.35s ease-in-out;

    &__close {
      text-align: right;
      margin: -10px 16px 5px 0;
      float: right;
      cursor: pointer;
      .oa-icon {
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  &__backdrop {
    background: transparent;
    right: 100%;
  }

  &--active {
    .oa-burger-menu__backdrop {
      right: 0;
    }

    .oa-burger-menu__menu {
      left: 0;
    }

    .oa-burger__backdrop {
      position: fixed;
      background: transparent;
      padding: 12px;
      left: 0;
      right: 0;
    }
  }

  .oa-left-nav {
    background: transparent;
    color: $white;
    clear: right;

    &__sidebar {
      border: none;
      width: 100%;

      &__hide-all {
        display: none;
      }

      &__group {
        padding: 0;
        position: relative;

        &:last-child {
          .oa-left-nav__sidebar__group__subgroup:before {
            height: 0;
          }
        }

        &--active {
          background-color: transparent;
          border: none;
        }

        &--expanded {
          .oa-left-nav__sidebar__group__subgroup:after {
            content: ' ';
            background: $oa-burger-menu-bg-hover;
            position: absolute;
            bottom: -1px;
            height: 1px;
            left: 30px;
            right: 30px;
            display: block;
          }
        }

        &__title {
          color: $white;
          font-size: 16px;
          line-height: 50px;
          padding: 0 45px 0 50px;
          width: 100%;
          position: relative;
          transition: background-color 0.35s ease-in-out;

          &:before {
            content: ' ';
            background: $oa-burger-menu-bg-hover;
            position: absolute;
            bottom: -1px;
            height: 1px;
            left: 30px;
            right: 30px;
            display: block;
          }

          &:hover {
            background-color: $oa-burger-menu-bg-hover;
            color: $white;
            &:before {
              visibility: hidden;
            }
          }

          &__icon {
            .oa-icon svg {
              margin: -2px 0 13px -15px;
              width: 20px;
              height: 20px;
              color: $white;
            }
          }

          &__collapse {
            display: block;
            float: right;

            .oa-icon svg {
              margin: 0 0 13px 0;
              width: 15px;
              height: 15px;
              color: $white;
            }
          }

          &.active {
            .oa-left-nav__menu__group__subgroup__title .oa-icon svg {
              color: $white;
            }
          }
        }

        &__subgroup {
          svg {
            display: none;
          }

          padding: 10px 0;

          &__line {
            display: none;
          }
          &__item {
            width: 100%;
          }
          .oa-left-nav__sidebar__link {
            font-family: $font-family-sans-serif;
            font-size: 14px;
            display: block;
            line-height: 35px;
            border-left: none;
            padding-left: 60px;
            color: $white;
            margin: 0;

            &.active {
              border-left: none;
              color: $white;
              line-height: 35px;
            }

            &:hover {
              color: $white;
              border-left: none;
              line-height: 35px;
            }

            &:before {
              height: 0;
            }

            &:after {
              display: none;
            }
          }
        }

      }

      &__link {
        display: block;
        padding: 0;
        margin: 0;
        color: $white;
        line-height: 50px;
        font-size: 16px;
        padding: 0 0 0 50px;
        border-left: none;
        position: relative;
        transition: background-color 0.35s ease-in-out;

        .oa-icon svg {
          margin: -2px 0 13px -15px;
          width: 20px;
          height: 20px;
          color: $white;
        }

        &:hover {
          background-color: $oa-burger-menu-bg-hover;
          text-decoration: none;
          color: $white;
          line-height: 50px;
          &:before {
            height: 0;
          }
        }

        &:before {
          content: ' ';
          background: $oa-burger-menu-bg-hover;
          position: absolute;
          bottom: -1px;
          height: 1px;
          left: 30px;
          right: 30px;
          display: block;
        }

        &:last-child {
          &:before {
            height: 0;
          }
        }

        &.active {
          border-left: none;
          color: $white;
          line-height: 50px;
          &:after {
            @include draw-circle(10px);
            content: '';
            position: absolute;
            left: 30px;
            top: 50%;
            margin-top: -6px;
            border: 1px solid;
          }
        }
      }
    }
  }
}
